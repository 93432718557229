import { QuoteStep } from '../Screens/project/components/NavBar';
import { AdditionalService } from './additionalServices';
import { api } from './api';
import { Address } from './user';

export const quoteStatus = [
  'DRAFT',
  'WAITING',
  'WAITING_HEAD_OFFICE',
  'QUOTE',
  'WAITING_ORDER',
  'ORDER_VALIDATED',
] as const;

export type QuoteStatus = (typeof quoteStatus)[number];

export const quoteFileType = [
  'CLIENT_QUOTE',
  'CLIENT_DELIVERY_QUOTE',
  'COMPANY_QUOTE',
  'DELIVERY_QUOTE',
  'DELIVERY_ORDER',
];

export type QuoteFileType = (typeof quoteFileType)[number];

export type QuoteFile = {
  key: string;
  type: QuoteFileType;
  assemblyId: number;
  quoteNumber: string;
  supplier?: string;
  totalPrice?: string;
  deliveryDelay?: string;
};

export type ReducedQuote = {
  id: number;
  quoteNumber: string;
  updatedAt: string;
  createdAt: string;
  image: string;
  customImage: string;
  status: QuoteStatus;
  familyRef: string;
  model: string;
  granit: string;
  quoteId: string;
  user: {
    company: {
      reason: string;
      name: string;
    };
  };
  assemblyId: number;
  assembly: {
    pictureUrl: string;
  };
  quoteFile: QuoteFile[];
  step: QuoteStep;
  price?: number;
};

export type Quote = {
  id?: number;
  quoteId?: string;
  customImage?: string;
  clientAddress?: Address;
  familyRef?: string;
  clientFirstName?: string;
  clientLastName?: string;
  cemetary?: string;
  concessionName?: string;
  concessionHeight?: number;
  concessionWidth?: number;
  useDimensions?: boolean;
  assemblyId: number;
  step: QuoteStep;
  status?: QuoteStatus;
  userId?: string;
  detailed?: boolean;
  discount?: number;
  additionalServicesOnQuote?: { additionalService: AdditionalService }[];
  installations?: Installations[];
};

export type Installations = {
  price: number;
  name: string;
};

export const getQuotes = async (specialQuery?: 'admin' | 'agencies') => {
  const { data } = await api.get<ReducedQuote[]>(`/quote${specialQuery ? `/${specialQuery}` : ''}`);
  return data;
};

export const createQuote = async (datas: Quote, userId: string) => {
  return api.post<string>(`/quote/${userId}`, datas);
};

export const updateAssemblyQuote = async ({
  oldAssemblyId,
  ...datas
}: Partial<Quote> & {
  oldAssemblyId: number;
  quoteLines?: any[];
  installations?: { price: number; name: string }[];
  quoteImages?: string[];
}) => {
  return api.patch<string>(`/quote/assembly/${oldAssemblyId}`, datas);
};

export const updateQuote = async ({
  assemblyId,
  type,
  ...datas
}: Partial<Quote> & { assemblyId: number; type: QuoteFileType }) => {
  const { data } = await api.patch<ReducedQuote>(`/quote/${assemblyId}`, { ...datas, type });
  return data;
};

export const orderQuotes = async (assemblyIds: number[]) => {
  return api.post<void>(`quote/order`, {}, { params: { assemblyIds } });
};

export const duplicateQuotes = async (assemblyIds: number[]) => {
  return api.post<void>(`quote/duplicate`, {}, { params: { assemblyIds } });
};

export const deleteQuotes = async (assemblyIds: number[]) => {
  return api.delete<void>(`quote/delete`, { params: { assemblyIds } });
};

export const confirmOrders = async (assemblyIds: number[]) => {
  return api.post<void>(`quote/order/confirm`, {}, { params: { assemblyIds } });
};

export const updateAdditionalServices = async (
  assemblyId: number,
  additionalServices: AdditionalService[],
) => {
  return api.patch<void>(`/quote/additionalService/${assemblyId}`, additionalServices);
};

export const updateQuoteCustomImage = async (id: number, customImage: string | null) => {
  return api.patch<void>(`/quote/customImage/${id}`, { customImage });
};
