/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { CircularProgress, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { Layer, Rect, Stage } from 'react-konva';
import { getPiece } from 'src/utils/configurator.utils';
import { Box3 } from 'three';

import { KanvasEngraving } from './Engravings/KanvasEngraving';
import { KanvasPattern } from './Patterns/KanvasPattern';

type KanvasEditorProps = {
  kanvasSize: { width: number; height: number } | undefined;
  orientation: {
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  };
  setPatterns: (patterns: { [key: string]: { [key: string]: any }[] }) => void;
  patterns: {
    [key: string]: { [key: string]: any }[];
  };
  pieces: any[];
  pieceIndex: number;
  selectedPattern: number | undefined;
  setSelectedPattern: (selectedPattern: number | undefined) => void;
  stageRef: React.MutableRefObject<any>;
  orbitRef: React.MutableRefObject<any>;
  customSteles: any[];
  customPlatings: any[];
  shouldUpdateTexture: React.MutableRefObject<{
    pieceUUID: string;
    orientation: string;
  } | null>;
  isPatternsLoaded: { [key: string]: boolean };
  setIsPatternsLoaded: (isPatternsLoaded: { [key: string]: boolean }) => void;
};

export const KanvasEditor = ({
  kanvasSize,
  orientation,
  setPatterns,
  patterns,
  pieces,
  pieceIndex,
  selectedPattern,
  setSelectedPattern,
  stageRef,
  orbitRef,
  customSteles,
  customPlatings,
  shouldUpdateTexture,
  isPatternsLoaded,
  setIsPatternsLoaded,
}: KanvasEditorProps) => {
  const [ratio, setRatio] = useState<number>();
  const [isMouseOverCanvas, setIsMouseOverCanvas] = useState<boolean>(false);

  const piece = getPiece(pieces, customSteles, customPlatings, pieceIndex);
  useEffect(() => {
    if (kanvasSize && piece) {
      const pieceBox = new Box3().setFromArray(
        // eslint-disable-next-line array-callback-return
        piece.geometry.attributes.position.array.map((element: any, index: number) => {
          if (index % 3 === 0) return element;
          if (index % 3 === 1) return element;
          if (index % 3 === 2) return element;
        }),
      );
      const x = (pieceBox.max.x - pieceBox.min.x) * 100; // largeur
      setRatio(kanvasSize.width / x);
    }
  }, [kanvasSize, pieceIndex, pieces, customSteles, piece]);

  const isTexturesLoading = () => {
    if (
      !patterns[piece?.uuid] ||
      !patterns[piece?.uuid][`${orientation.key}${orientation.inverse ? '_inverse' : ''}`]
    )
      return false;

    const loadedPatterns =
      patterns[piece?.uuid][`${orientation.key}${orientation.inverse ? '_inverse' : ''}`]?.elements;

    for (let i = 0; i < loadedPatterns.length; i += 1) {
      if (isPatternsLoaded[i]) return true;
    }
    return false;
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
      }}
    >
      <>
        {isTexturesLoading() ? (
          <LoadingContainer>
            <CircularProgress size={42} color="primary" />
          </LoadingContainer>
        ) : null}
        <Container
          style={{ pointerEvents: 'all' }}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          {kanvasSize ? (
            <Stage
              ref={stageRef}
              width={kanvasSize.width}
              height={kanvasSize.height}
              style={{
                width: kanvasSize.width,
                height: kanvasSize.height,
              }}
              onMouseEnter={() => setIsMouseOverCanvas(true)}
              onMouseLeave={() => {
                setIsMouseOverCanvas(false);

                shouldUpdateTexture.current = {
                  pieceUUID: piece.uuid,
                  orientation: orientation.key,
                };

                setTimeout(() => {
                  setPatterns({ ...patterns });
                }, 50);
              }}
            >
              <Layer>
                <Rect width={kanvasSize.width} height={kanvasSize.height} />
                {patterns[piece.uuid] &&
                  patterns[piece.uuid][
                    `${orientation.key}${orientation.inverse ? '_inverse' : ''}`
                  ]?.elements.map((el: any, elIndex: number) => {
                    const pieceUUID = piece.uuid;
                    return (
                      <>
                        {el.type === 'image' ? (
                          <KanvasPattern
                            patternIndex={elIndex}
                            setSelectedPattern={setSelectedPattern}
                            isSelected={selectedPattern === elIndex}
                            stageRef={stageRef}
                            pattern={el}
                            orientation={orientation}
                            setPatterns={setPatterns}
                            patterns={patterns}
                            pieceUUID={pieceUUID}
                            orbitRef={orbitRef}
                            kanvasSize={kanvasSize}
                            ratio={ratio}
                            isMouseOverCanvas={isMouseOverCanvas}
                            isPatternsLoaded={isPatternsLoaded}
                            setIsPatternsLoaded={setIsPatternsLoaded}
                          />
                        ) : null}

                        {el.type === 'text' ? (
                          <KanvasEngraving
                            orbitRef={orbitRef}
                            patternIndex={elIndex}
                            setSelectedPattern={setSelectedPattern}
                            stageRef={stageRef}
                            pattern={el}
                            orientation={orientation}
                            setPatterns={setPatterns}
                            patterns={patterns}
                            pieceUUID={pieceUUID}
                            ratio={ratio}
                            kanvasSize={kanvasSize}
                          />
                        ) : null}
                      </>
                    );
                  })}
              </Layer>
            </Stage>
          ) : null}
        </Container>
      </>
    </div>
  );
};

const Container = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: window.innerHeight - 100,
  width: '65%',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const LoadingContainer = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: window.innerHeight - 100,
  width: '65%',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
