/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import { PatternType } from 'src/constants/pattern';
import { getPiece } from 'src/utils/configurator.utils';
import { Euler, Mesh, PerspectiveCamera, Vector2, Vector3, WebGLRenderer } from 'three';
import * as THREE from 'three';
import { generateUUID } from 'three/src/math/MathUtils';

import { centerCameraOnPiece, updateCameraPosition, updateOrbit } from 'src/utils/pattern';
import { StateNames, States } from '../Screens/Configurator';

type PatternsProps = {
  pieces: any[];
  customSteles: any[];
  customPlatings: any[];
  pieceIndex: number;
  setPatterns: (patterns: { [key: string]: { [key: string]: any }[] }) => void;
  patterns: {
    [key: string]: { [key: string]: any }[];
  };
  orbitRef: React.MutableRefObject<any>;
  centerCamera: () => void;
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  gl: WebGLRenderer | undefined;

  orientation: {
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  };

  setOrientation: (orientation: { key: 'x' | 'y' | 'z' | 'free'; inverse: boolean }) => void;
  shouldUpdateTexture: any;
};

export const usePattern = ({
  pieces,
  pieceIndex,
  setPatterns,
  patterns,
  orbitRef,
  centerCamera,
  setValue,
  gl,
  orientation,
  setOrientation,
  customSteles,
  customPlatings,
  shouldUpdateTexture,
}: PatternsProps) => {
  const [isButtonOpen, setIsButtonOpen] = useState<boolean>(false);
  const [selectedPiece, setSelectedPiece] = useState<any | null>(null);
  const [selectedImportPage, setSelectedImportPage] = useState<PatternType>();

  const [kanvasSize, setKanvasSize] = useState<{ width: number; height: number }>();

  const piece = getPiece(pieces, customSteles, customPlatings, pieceIndex);

  const calculateScreenSize = (
    mesh: Mesh,
    camera: PerspectiveCamera,
    canvasWidth: number,
    canvasHeight: number,
    ignoreAxis: 'x' | 'y' | 'z',
  ): { width: number; height: number } => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const array = mesh.geometry.attributes.position.array as number[];

    const boundingBox = new THREE.Box3().setFromArray(array);

    const size = boundingBox.getSize(new THREE.Vector3());

    const fov = camera.fov * (Math.PI / 180);
    const distance = camera.position.distanceTo(mesh.position) / camera.zoom;

    const height = 2 * Math.tan(fov / 2) * distance;
    const width = height * camera.aspect;

    let scaleX: number;
    let scaleY: number;
    if (ignoreAxis === 'z') {
      scaleX = size.x / width;
      scaleY = size.y / height;
    } else if (ignoreAxis === 'x') {
      scaleX = size.z / width;
      scaleY = size.y / height;
    } else {
      scaleX = size.x / width;
      scaleY = size.z / height;
    }

    return {
      width: scaleX * canvasWidth,
      height: scaleY * canvasHeight,
    };
  };

  const updateKanvas = () => {
    if (pieceIndex === -1) return;
    const mesh = new THREE.Mesh(piece.geometry, new THREE.MeshBasicMaterial({ color: 'red' }));
    centerCameraOnPiece(pieceIndex, piece, orbitRef);
    const canvasSize = gl?.getSize(new Vector2());

    if (orientation.key !== 'free') {
      setKanvasSize(
        calculateScreenSize(
          mesh,
          orbitRef.current.object,
          canvasSize?.x ?? 0,
          canvasSize?.y ?? 0,
          orientation.key,
        ),
      );
    }
  };

  useEffect(() => {
    updateOrbit(
      gl,
      orbitRef,
      pieceIndex,
      pieces,
      customSteles,
      customPlatings,
      orientation,
      centerCamera,
      setValue,
      updateKanvas,
    );
    return () => {
      centerCamera();

      orbitRef.current.object.zoom = 1;
      orbitRef.current.object.fov = 40;
      orbitRef.current.enablePan = true;
      orbitRef.current.enableRotate = true;
      setValue('allPieces', true);
    };
  }, [orientation]);

  useEffect(() => {
    if (orientation.key !== 'free')
      updateOrbit(
        gl,
        orbitRef,
        pieceIndex,
        pieces,
        customSteles,
        customPlatings,
        orientation,
        centerCamera,
        setValue,
        updateKanvas,
      );
    return () => {
      if (orientation.key !== 'free') {
        centerCamera();

        orbitRef.current.enablePan = true;
        orbitRef.current.enableRotate = true;
      }
    };
  }, [pieceIndex]);

  const [patternsTmp, setPatternsTmp] = useState<{ [key: string]: { [key: string]: any }[] }>({});

  useEffect(() => {
    const copiedDict: {
      [key: string]: {
        [key: string]: any;
      }[];
    } = {};

    Object.keys(patterns).forEach((elKey) => {
      copiedDict[elKey] = [];

      Object.keys(patterns[elKey]).forEach((elementKey) => {
        copiedDict[elKey][elementKey] = {
          ...patterns[elKey][elementKey],
          elements: patterns[elKey][elementKey].elements.map((element: any) => {
            if (element.type === 'text') {
              return {
                ...element,
                position: {
                  x: String(element.position.x),
                  y: String(element.position.y),
                  z: String(element.position.z),
                },
              };
            }
            return {
              ...element,
              position: {
                x: String(element.position.x),
                y: String(element.position.y),
                z: String(element.position.z),
              },
              scale: { x: String(element.scale.x), y: String(element.scale.y) },
              rotation: {
                x: String(element.rotation.x),
                y: String(element.rotation.y),
                z: String(element.rotation.z),
              },
            };
          }),
        };
      });
    });

    setPatternsTmp(copiedDict);
  }, [patterns]);

  const changePattern = (
    pattern: {
      uuid: string;
      pieceUUID: string;
      orientation: 'x' | 'y' | 'z';
    },
    majorKey: 'position' | 'scale' | 'inverse_horizontal' | 'inverse_vertical',
    value: any,
    minorKey?: 'x' | 'y' | 'z',
  ) => {
    const newPatternsTmp = { ...patternsTmp };

    shouldUpdateTexture.current = {
      pieceUUID: pattern.pieceUUID,
      orientation: orientation.key,
    };
    newPatternsTmp[pattern.pieceUUID][pattern.orientation].elements = patterns[pattern.pieceUUID][
      pattern.orientation
    ].elements.map((element: any) => {
      if (element.uuid === pattern.uuid) {
        if (minorKey) {
          const elementToChange = {
            x: element[majorKey].x,
            y: element[majorKey].y,
            z: element[majorKey].z,
          };
          elementToChange[minorKey] = value;
          return {
            ...element,
            [majorKey]: elementToChange,
          };
        }
        return {
          ...element,
          [majorKey]: value,
        };
      }
      return element;
    });
    setPatternsTmp(newPatternsTmp);
  };

  const applyPatternsTmp = () => {
    const copiedDict: {
      [key: string]: {
        [key: string]: any;
      }[];
    } = {};

    Object.keys(patternsTmp).forEach((elKey) => {
      copiedDict[elKey] = [];

      Object.keys(patternsTmp[elKey]).forEach((elementKey) => {
        copiedDict[elKey][elementKey] = {
          ...patternsTmp[elKey][elementKey],
          elements: patternsTmp[elKey][elementKey].elements.map((element: any) => {
            if (element.type === 'text') {
              return {
                ...element,
                position: {
                  x: String(element.position.x),
                  y: String(element.position.y),
                  z: String(element.position.z),
                },
              };
            }

            return {
              ...element,
              position: new Vector3(
                Number.parseFloat(element.position.x),
                Number.parseFloat(element.position.y),
                Number.parseFloat(element.position.z),
              ),
              scale: new Vector2(
                Number.parseFloat(element.scale.x),
                Number.parseFloat(element.scale.y),
              ),
              rotation: new Euler(
                Number.parseFloat(element.rotation.x),
                Number.parseFloat(element.rotation.y),
                Number.parseFloat(element.rotation.z),
                'XYZ',
              ),
            };
          }),
        };
      });
    });

    setPatterns(copiedDict);
  };

  const getPatterns = () => {
    if (patternsTmp[piece?.uuid]) {
      if (orientation.key === 'free') {
        const patternsObject = Object.values(patternsTmp[piece.uuid]);
        const mappedPatterns = patternsObject.map((el) => {
          return {
            ...el,
            elements: el.elements.map((element: any) => {
              return { ...element, orientation: el.id };
            }),
          };
        });
        const flatedPatterns = mappedPatterns.flatMap((el) => el.elements);
        return flatedPatterns;
      }
      const patternsObject = Object.values(patternsTmp[piece.uuid]);
      const mappedPatterns = patternsObject.map((el) => {
        return {
          ...el,
          elements: el.elements.map((element: any) => {
            return { ...element, orientation: el.id };
          }),
        };
      });
      const flatedPatterns = mappedPatterns.flatMap((el) => el.elements);
      const filteredPattens = flatedPatterns.filter(
        (el) => el.orientation === `${orientation.key}${orientation.inverse ? '_inverse' : ''}`,
      );
      return filteredPattens;
    }
    return [];
  };

  const addText = () => {
    const newPatternsTmp = { ...patterns };

    if (!newPatternsTmp[piece.uuid]) {
      newPatternsTmp[piece.uuid] = [];
    }

    if (newPatternsTmp[piece.uuid][`${orientation.key}${orientation.inverse ? '_inverse' : ''}`]) {
      newPatternsTmp[piece.uuid][
        `${orientation.key}${orientation.inverse ? '_inverse' : ''}`
      ].elements?.push({
        uuid: generateUUID(),
        pieceUUID: piece.uuid,
        orientation: `${orientation.key}${orientation.inverse ? '_inverse' : ''}`,
        position: new Vector3(0, 0, 0),
        type: 'text',
        text: 'texte',
        fontSize: '3',
        letterSpacing: '0',
        fontFamily: 'arial',
        align: 'left',
        color: '#101828',
      });
    } else {
      newPatternsTmp[piece.uuid][`${orientation.key}${orientation.inverse ? '_inverse' : ''}`] = {
        id: `${orientation.key}${orientation.inverse ? '_inverse' : ''}`,
        texture: null,
        elements: [
          {
            uuid: generateUUID(),
            pieceUUID: piece.uuid,
            orientation: `${orientation.key}${orientation.inverse ? '_inverse' : ''}`,
            position: new Vector3(0, 0, 0),
            type: 'text',
            text: 'texte',
            fontSize: '3',
            letterSpacing: '0',
            fontFamily: 'arial',
            align: 'left',
            color: '#101828',
          },
        ],
      };
    }
    setPatterns(newPatternsTmp);
  };

  const changeText = (
    pattern: {
      uuid: string;
      pieceUUID: string;
      orientation: 'x' | 'y' | 'z';
    },
    key: 'text' | 'fontSize' | 'letterSpacing' | 'fontFamily' | 'align' | 'color',
    value: string,
  ) => {
    const newPatterns = { ...patterns };
    newPatterns[pattern.pieceUUID][pattern.orientation].elements = patterns[pattern.pieceUUID][
      pattern.orientation
    ].elements.map((element: any) => {
      if (element.uuid === pattern.uuid) {
        return {
          ...element,
          [key]: value,
        };
      }
      return element;
    });
    setPatterns(newPatterns);
  };

  return {
    kanvasSize,
    selectedImportPage,
    setSelectedImportPage,
    setSelectedPiece,
    isButtonOpen,
    setIsButtonOpen,
    selectedPiece,
    updateKanvas,
    patternsTmp,
    changePattern,
    applyPatternsTmp,
    getPatterns,
    addText,
    changeText,
  };
};
