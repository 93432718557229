import { api } from './api';

export type Address = {
  address: string;
  addressDetails: string;
  city: string;
  zipCode: string;
  country: string;
};

export type RegisterUserData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  headOffice: boolean;
  name: string;
  phone: string;
  reason: string;
  function: string;
  siret: string;
  address: Address;
};

export type UserCoordinates = {
  phone: string;
  deliveryAddress: Address;
  billingAddress: Address;
};

export type Roles = 'ADMIN' | 'AGENCY' | 'HEAD_OFFICE';
export type UserStatus = 'PENDING' | 'ACTIVATED' | 'DISABLED';

export const ELEVATED_ROLES: Roles[] = ['ADMIN', 'HEAD_OFFICE'];

type Company = {
  reason: string;
  name: string;
  function: string;
  canEditQuoteSettings: boolean;
  canEditCoeffQuoteSettings: boolean;
  canEditAdditionalService: boolean;
  isOnMaintenance: boolean;
} & UserCoordinates;

export type ReducedCompany = {
  id: string;
  name: string;
  reason: string;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: Roles;
  profilePicture: string;
  company: Company;
  status: UserStatus;
  leaderGranitCompanyId: string | null;
  leaderGranitCompany?: {
    assistancePhoneNumber: string;
  };
  userCatalog: {
    catalog: {
      name: string;
      id: string;
    };
  }[];
  quoteSettings: {
    activeDeposit: boolean;
    deposit: number;
    euroDeposit: boolean;
    activeSoleInstallation: boolean;
    soleInstallation: number;
    addInstallationToSolePrice: boolean;
    activeMonumentInstallation: boolean;
    monumentInstallation: number;
    activeCineraryInstallation: boolean;
    cineraryInstallation: number;
    activeSpecialInstallation: boolean;
    specialInstallation: number;
    addInstallationToMonumentPrice: boolean;
    activeCustomDelivery: boolean;
    customDelivery: number;
    postageDue: boolean;
    displayDelivery: boolean;
    simplifiedQuote: boolean;
    footerText?: string;
  };
  userCoefficients: {
    isDetailed: boolean;
    frenchEco: number;
    frenchNormal: number;
    importEco: number;
    importNormal: number;
    patterns: number;
    accessories: number;
    delivery: number;
    coeffFr: number;
    coeffImport: number;
    coeffEcoImport: number;
    coeffEcoFr: number;
    engravingGoldId: number;
    engravingWhiteId: number;
    engravingGold: {
      coeff1: number;
      coeff2: number;
      coeff3: number;
      coeff4: number;
      coeff5: number;
      coeff6: number;
      coeff7: number;
    };
    engravingWhite: {
      coeff1: number;
      coeff2: number;
      coeff3: number;
      coeff4: number;
      coeff5: number;
      coeff6: number;
      coeff7: number;
    };
  };
  countOrders: number;
  countQuotes: number;
  totalIncome: number;
  deliveryCost: { code: string; price: string };
  detailedCoefs: {
    [K in 'MONUMENT' | 'CINERARY' | 'COLUMBARIUM' | 'SOLE/PLATING']: {
      [key: string]: number;
    };
  };
};

export type ReducedUser = Omit<User, 'deliveryAddress' | 'billingAddress' | 'profilePicture'> & {
  id: string;
  company: Company & {
    headOffice?: {
      headOffice: {
        company: {
          reason: string;
          user: {
            id: string;
          };
        };
      };
    };
  };
};

export type Agency = {
  company: Omit<Company, 'function' | 'reason'> & { user: { id: string }; id: string };
  control: boolean;
};

type UpdateUserData = {
  name?: string;
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  isOnMaintenance?: boolean;
  deliveryAddress?: Address;
  billingAddress?: Address;
  leaderGranitCompanyId?: string;
  catalogIds?: string[];
};

type UpdatePasswordData = {
  oldPassword: string;
  newPassword: string;
};

export const signup = async (datas: RegisterUserData) => {
  return api.post<void>('/user/signup', datas);
};

export const getUserDetails = async (userId?: string) => {
  const { data } = await api.get<User>(`/user${userId ? `/${userId}` : ''}`);

  return data;
};

export const getIsGlobalMaintenanceMode = async () => {
  const { data } = await api.get<boolean>(`/user/maintenance`);

  return data;
};

export const updateIsGlobalMaintenanceMode = async (datas: { isMaintenanceMode: boolean }) => {
  return api.post(`/user/maintenance`, datas);
};

export const getAllUsers = async () => {
  const { data } = await api.get<ReducedUser[]>('/user/all');

  return data;
};

export const getProfilePictureUploadUrl = async () => {
  const { data } = await api.get<string>('/user/upload-url');

  return data;
};

export const updateUser = async (datas: UpdateUserData, userId: string) => {
  return api.patch(`/user/${userId}`, datas);
};

export const updatePassword = async (datas: UpdatePasswordData) => {
  return api.put<void>('/user/password', datas);
};

export const updateProfilePicture = async (datas: string, userId: string) => {
  return api.put<void>(`/user/profile-picture/${userId}`, {}, { params: { key: datas } });
};

export const linkHeadOfficeAgency = async (datas: { headOfficeId: string; agencyId: string }) => {
  return api.post<void>('/user/agency', datas);
};

export const getCompanyAgencies = async (userId: string) => {
  const { data } = await api.get<Agency[]>(`/user/agencies/${userId}`);

  return data;
};

export const getCompany = async (userId: string) => {
  const { data } = await api.get<Company>(`/user/company/${userId}`);

  return data;
};

export const getNotLinkedAgencies = async () => {
  const { data } = await api.get<ReducedCompany[]>('/user/not-linked-agencies');

  return data;
};

export const linkAgencies = async (datas: { agencyIds: string[] }, userId: string) => {
  return api.post(`/user/agency/${userId}`, datas);
};
export const unlinkAgencies = async (datas: { agencyIds: string[] }) => {
  return api.post(`/user/agency/delete`, datas);
};

export const activateUser = async (
  datas: { catalogIds: string[]; leaderGranitCompanyId: string },
  userId: string,
) => {
  return api.put(`/user/activate/${userId}`, datas);
};

export const disableUser = async (userId: string) => {
  return api.put(`/user/disable/${userId}`);
};

export const updateControlMode = async (userId: string, value: boolean) => {
  return api.put(`/user/agency/${userId}`, {}, { params: { value } });
};

export const updateCanEdit = async (userId: string, optionName: string, value: boolean) => {
  return api.put(`/user/agency/${userId}/canEdit`, {}, { params: { value, optionName } });
};
