/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable operator-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable unicorn/no-array-for-each */
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useLoadDataList from 'src/hook/useLoadDataList';
import { StateNames, States } from 'src/Screens/Configurator';
import { Assembly } from 'src/services/assemblies';
import {
  getLinkedPiecesOnAssembly,
  getLinkedPiecesOnUnderAssembly,
  Modification,
} from 'src/services/configurator';
import { getIndexWithAxe, getPieceSize } from 'src/utils/configurator.utils';
import { applyModification } from 'src/utils/transform.utils';
import styled from 'styled-components';
import { Box3, Vector3 } from 'three';

type TransformProps = {
  changeGeometry: (indexToChange: number, newGeometry: number[]) => void;
  checkCollision: (
    pieceIndexToCheck: number,
    axe: 'x' | 'y' | 'z',
    checkOnlySelectedPiece?: boolean,
  ) => void;
  pieces: any[];
  pieceIndex: number;
  assembly: Assembly | null;
  activePieces: any[];
  advancedMode: boolean;
  reloadPiece: boolean;
  history: {
    [k: string]: {
      [k: string]: Float32Array;
    };
  };
  getValue: <T extends StateNames>(stateName: T) => States[T];
};

export const Transform = ({
  changeGeometry,
  checkCollision,
  pieces,
  pieceIndex,
  assembly,
  activePieces,
  advancedMode,
  reloadPiece,
  history,
  getValue,
}: TransformProps) => {
  const [error, setError] = useState<string>();

  const { data: linkedPiecesOnAssembly } = useLoadDataList(() =>
    getLinkedPiecesOnAssembly(getValue('modelId')),
  );

  const underAssemblyId = activePieces[pieceIndex]?.underAssembly
    ? activePieces[pieceIndex]?.underAssembly.id
    : -1;

  const { data: linkedPiecesOnUnderAssembly, onRefresh: refreshLinkedPiecesOnUnderAssembly } =
    useLoadDataList(() => getLinkedPiecesOnUnderAssembly(underAssemblyId), [underAssemblyId]);

  const [size, setSize] = useState<{
    x: string;
    y: string;
    ySensInverted: boolean;
    z: string;
    zSensInverted: boolean;
  }>({ x: '', y: '', ySensInverted: false, z: '', zSensInverted: false });

  const [savedSize, setSavedSize] = useState<{
    x: string;
    y: string;
    z: string;
  }>({ x: size.x, y: size.y, z: size.z });

  useEffect(() => {
    if (activePieces[pieceIndex]?.underAssembly && !advancedMode) {
      const activePiecesWithGeometry = activePieces.map((el, elIndex) => {
        return {
          ...el,
          piece: pieces[elIndex],
        };
      });
      const underAsssemblyPieces = activePiecesWithGeometry.filter(
        (el) => el.underAssembly?.uuid === activePieces[pieceIndex].underAssembly.uuid,
      );
      let box = new Box3();
      for (const el of underAsssemblyPieces) {
        const elBox = new Box3();
        if (!el.piece) return;
        const piecePosition = el.piece.position;
        const geometry = [...el.piece.geometry.attributes.position.array];
        for (let i = 0; i < geometry.length; i += 3) {
          geometry[i] = geometry[i] + piecePosition.x;
          geometry[i + 1] = geometry[i + 1] + piecePosition.y;
          geometry[i + 2] = geometry[i + 2] + piecePosition.z;
        }
        elBox.setFromArray(geometry);
        box = box.union(elBox);
      }
      const sizeObj = {
        ...size,
        x: String(Math.round((box.max.x - box.min.x) * 100)),
        y: String(Math.round((box.max.y - box.min.y) * 100)),
        z: String(Math.round((box.max.z - box.min.z) * 100)),
      };
      setSize(sizeObj);
      setSavedSize(sizeObj);
    } else {
      const geometry = pieces[pieceIndex].geometry.attributes.position.array;
      const sizeObj = {
        ...size,
        x: getPieceSize('x', geometry),
        y: getPieceSize('y', geometry),
        z: getPieceSize('z', geometry),
      };
      setSize(sizeObj);
      setSavedSize(sizeObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieceIndex, advancedMode, reloadPiece]);

  const getCenterPiece = (piece: any) => {
    const box = new Box3().setFromArray(piece.geometry.attributes.position.array);
    const center = box.getCenter(new Vector3());

    return new Vector3(
      center.x + piece.position.x,
      center.y + piece.position.y,
      center.z + piece.position.z,
    );
  };

  const isPieceInCollision = (piece1: any, piece2: any, axe: 'x' | 'y' | 'z') => {
    const margin = 0.1;
    const box1 = new Box3()
      .setFromArray(piece1.geometry.attributes.position.array)
      .translate(piece1.position);
    const box2 = new Box3()
      .setFromArray(piece2.geometry.attributes.position.array)
      .translate(piece2.position);

    const center1 = box1.getCenter(new Vector3());
    const center2 = box2.getCenter(new Vector3());

    const size1 = box1.getSize(new Vector3());
    const size2 = box2.getSize(new Vector3());

    if (
      axe === 'x' &&
      center1.x + size1.x / 2 + margin >= center2.x - size2.x / 2 &&
      center1.x - size1.x / 2 - margin <= center2.x + size2.x / 2
    ) {
      return true;
    }
    if (
      axe === 'y' &&
      center1.y + size1.y / 2 + margin >= center2.y - size2.y / 2 &&
      center1.y - size1.y / 2 - margin <= center2.y + size2.y / 2
    ) {
      return true;
    }
    if (
      axe === 'z' &&
      center1.z + size1.z / 2 + margin >= center2.z - size2.z / 2 &&
      center1.z - size1.z / 2 - margin <= center2.z + size2.z / 2
    ) {
      return true;
    }

    return false;
  };

  const moveOtherPieces = (axe: 'x' | 'y' | 'z', delta: number, indexAlreadyChanged: number) => {
    const refCenter = getCenterPiece(pieces[indexAlreadyChanged]);
    const pieceToMove = pieces.map((el, elIndex) => {
      return { ...el, pieceIndex: elIndex };
    });

    const pieceToMoveFiltered = pieceToMove.filter((el) => {
      if (el.pieceIndex === indexAlreadyChanged) return false;
      return true;
    });

    pieceToMoveFiltered.forEach((el) => {
      const box = new Box3().setFromArray(el.geometry.attributes.position.array);
      const isColliding = isPieceInCollision(pieces[indexAlreadyChanged], el, axe);
      if (!isColliding || activePieces[el.pieceIndex].underAssembly) return;

      if (
        axe === 'x' &&
        delta < 0 &&
        box.min.y + el.position.y <= refCenter.y &&
        box.max.y + el.position.y >= refCenter.y
      ) {
        if (box.min[axe] + el.position[axe] > refCenter[axe]) {
          const newPosX = el.position.x + delta / 200;
          el.position.set(newPosX, el.position.y, el.position.z);
          el.needsUpdate = true;
        }
        if (box.max[axe] + el.position[axe] < refCenter[axe]) {
          const newPosX = el.position.x + delta / -200;
          el.position.set(newPosX, el.position.y, el.position.z);
          el.needsUpdate = true;
        }
      } else if (
        axe === 'z' &&
        box.min.y + el.position.y <= refCenter.y &&
        box.max.y + el.position.y >= refCenter.y &&
        box.min[axe] + el.position[axe] > refCenter[axe]
      ) {
        const newPosZ = el.position.z + delta / 100;
        el.position.set(el.position.x, el.position.y, newPosZ);
        el.needsUpdate = true;
      } else if (axe === 'y' && box.min[axe] + el.position[axe] > refCenter[axe]) {
        const newPosY = el.position.y + delta / 100;
        el.position.set(el.position.x, newPosY, el.position.z);
        el.needsUpdate = true;
      }
    });
  };

  const resize = (
    indexToChange: number,
    axe: 'x' | 'z' | 'y',
    geometryRef: any[],
    delta: number,
  ) => {
    moveOtherPieces(axe, delta, indexToChange);
    const actualSize = getPieceSize(
      axe,
      pieces[indexToChange].geometry.attributes.position.array,
      false,
    );

    const oldBox = new Box3().setFromArray(geometryRef);
    const oldCenter = new Vector3();
    oldBox.getCenter(oldCenter);

    if (size && actualSize) {
      const percentage =
        (Number.parseFloat(actualSize) + delta) / (Number.parseFloat(actualSize) / 100) / 100;

      const copyGeometry: number[] = Array.from({ length: geometryRef.length }).fill(0) as number[];
      for (let i = 0; i < geometryRef.length; i += 3) {
        copyGeometry[i] = geometryRef[i] - Number(oldCenter.x);
        copyGeometry[i + 1] = geometryRef[i + 1] - Number(oldCenter.y);
        copyGeometry[i + 2] = geometryRef[i + 2] - Number(oldCenter.z);
        copyGeometry[i + getIndexWithAxe(axe)] =
          copyGeometry[i + getIndexWithAxe(axe)] * percentage;
      }

      for (let i = 0; i < geometryRef.length; i += 3) {
        copyGeometry[i] = copyGeometry[i] + Number(oldCenter.x);
        copyGeometry[i + 1] = copyGeometry[i + 1] + Number(oldCenter.y);
        copyGeometry[i + 2] = copyGeometry[i + 2] + Number(oldCenter.z);
      }

      const newBox = new Box3().setFromArray(copyGeometry);
      const zDiff = newBox.min.z - oldBox.min.z;
      const yDiff = newBox.min.y - oldBox.min.y;

      for (let i = 0; i < geometryRef.length; i += 3) {
        copyGeometry[i + 1] = copyGeometry[i + 1] - yDiff * (size.ySensInverted ? -1 : 1);
        copyGeometry[i + 2] = copyGeometry[i + 2] - zDiff * (size.zSensInverted ? -1 : 1);
      }

      changeGeometry(indexToChange, copyGeometry);
      checkCollision(indexToChange, axe);
    }
  };

  const resizeUnderAssemblyPiece = (
    indexToChange: number,
    axe: 'x' | 'z' | 'y',
    geometryRef: any[],
    delta: number,
  ) => {
    const actualSize = getPieceSize(axe, pieces[indexToChange].geometry.attributes.position.array);
    const oldBox = new Box3().setFromArray(geometryRef);

    const center = oldBox.getCenter(new Vector3());

    if (size && actualSize) {
      const percentage =
        (Number.parseFloat(actualSize) + delta) / (Number.parseFloat(actualSize) / 100) / 100;

      const copyGeometry: number[] = Array.from({ length: geometryRef.length }).fill(0) as number[];

      for (let i = 0; i < geometryRef.length; i += 3) {
        copyGeometry[i] = geometryRef[i];
        copyGeometry[i + 1] = geometryRef[i + 1];
        copyGeometry[i + 2] = geometryRef[i + 2];
        if (axe === 'x') {
          copyGeometry[i + getIndexWithAxe(axe)] =
            (geometryRef[i + getIndexWithAxe(axe)] - center.x) * percentage + center.x;
        } else {
          copyGeometry[i + getIndexWithAxe(axe)] =
            geometryRef[i + getIndexWithAxe(axe)] * percentage;
        }
      }

      const newBox = new Box3().setFromArray(copyGeometry);
      const zDiff = newBox.min.z - oldBox.min.z;
      const yDiff = newBox.min.y - oldBox.min.y;

      for (let i = 0; i < geometryRef.length; i += 3) {
        copyGeometry[i + 1] = copyGeometry[i + 1] - yDiff * (size.ySensInverted ? -1 : 1);
        copyGeometry[i + 2] = copyGeometry[i + 2] - zDiff * (size.zSensInverted ? -1 : 1);
      }

      changeGeometry(indexToChange, copyGeometry);
      checkCollision(indexToChange, axe);
    }
  };

  useEffect(() => {
    if (
      Number.parseFloat(size.x) > Number.parseFloat(activePieces[pieceIndex].granit?.maxLength) ||
      Number.parseFloat(size.y) > Number.parseFloat(activePieces[pieceIndex].granit?.maxLength) ||
      Number.parseFloat(size.z) > Number.parseFloat(activePieces[pieceIndex].granit?.maxLength)
    ) {
      setError(
        `Attention, votre pièce dépasse la taille maximale de ce granit (${Number.parseFloat(
          activePieces[pieceIndex].granit?.maxLength,
        )}). Elle sera coupée.`,
      );
    } else if (error) {
      setError(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, activePieces[pieceIndex]?.granit]);

  const applyTransformOnAllPiece = (axe: 'x' | 'z' | 'y') => {
    if (size[axe] === savedSize[axe]) return;
    const checkMin = (value: string) => {
      if (Number.parseFloat(value) < Number.parseFloat(activePieces[pieceIndex].granit?.minLength))
        return activePieces[pieceIndex].granit?.minLength;
      return value;
    };

    if (activePieces[pieceIndex]?.underAssembly && !advancedMode) {
      const indexOfFirstPieceOnAssembly = activePieces.findIndex(
        (el) => el.underAssembly?.uuid === activePieces[pieceIndex].underAssembly.uuid,
      );

      const mappedPiecesOfUnderAssembly = activePieces.map((el, elIndex) => {
        return { ...el, pieceIndex: elIndex };
      });

      let modificationToApply: Modification | null = null;

      const delta = Number.parseFloat(checkMin(size[axe])) - Number.parseFloat(savedSize[axe]);

      // eslint-disable-next-line default-case
      switch (axe) {
        case 'x': {
          modificationToApply = activePieces[pieceIndex]?.underAssembly.modifications.find(
            (el: any) => el.type === 'WIDTH',
          );
          break;
        }
        case 'y': {
          modificationToApply = activePieces[pieceIndex]?.underAssembly.modifications.find(
            (el: any) => el.type === 'HEIGHT',
          );
          break;
        }
        case 'z': {
          modificationToApply = activePieces[pieceIndex]?.underAssembly.modifications.find(
            (el: any) => el.type === 'LENGTH',
          );

          break;
        }
        // No default
      }

      const modificationPieceIndex = activePieces.findIndex((el) => {
        if (
          el.underAssembly?.uuid === activePieces[pieceIndex]?.underAssembly.uuid &&
          el.id === modificationToApply?.pieceId
        ) {
          return true;
        }
        return false;
      });

      const pieceToApply = linkedPiecesOnUnderAssembly?.filter((el) => {
        return (
          el.piecesIndex.includes(modificationPieceIndex - indexOfFirstPieceOnAssembly) &&
          el.axis.includes(axe)
        );
      });

      if (modificationToApply) {
        if (axe === 'x' && delta < 0) {
          const pieceToMove = mappedPiecesOfUnderAssembly.filter((el) => {
            if (el.pieceIndex < indexOfFirstPieceOnAssembly) return false;

            const isPresent = pieceToApply.some((p) => p.piecesIndex.includes(el.pieceIndex));

            return !isPresent;
          });

          const modificationPiece = pieces[modificationPieceIndex];
          const modificationPieceBox = new Box3().setFromArray(
            modificationPiece?.geometry.attributes.position.array,
          );
          const center =
            (modificationPieceBox.min.x + modificationPieceBox.max.x) / 2 +
            modificationPiece.position.x;

          for (const el of pieceToMove) {
            const piece = pieces[el.pieceIndex];
            const box = new Box3().setFromArray(piece?.geometry.attributes.position.array);

            if (box.max.x + piece.position.x < center) {
              const newPosX = piece.position.x + delta / -200;
              piece.position.set(newPosX, piece.position.y, piece.position.z);
            }
            if (box.min.x + piece.position.x > center) {
              const newPosX = piece.position.x + delta / 200;
              piece.position.set(newPosX, piece.position.y, piece.position.z);
            }
            piece.needsUpdate = true;
          }
        } else if (axe === 'z') {
          const modificationPiece = pieces[modificationPieceIndex];
          const modificationPieceBox = new Box3().setFromArray(
            modificationPiece?.geometry.attributes.position.array,
          );
          const center =
            (modificationPieceBox.min.z + modificationPieceBox.max.z) / 2 +
            modificationPiece.position.z;

          const pieceToMove = mappedPiecesOfUnderAssembly.filter((el) => {
            if (el.pieceIndex < indexOfFirstPieceOnAssembly) return false;

            const isPresent = pieceToApply.some((p) => p.piecesIndex.includes(el.pieceIndex));

            return !isPresent;
          });

          for (const el of pieceToMove) {
            const piece = pieces[el.pieceIndex];
            const box = new Box3().setFromArray(piece?.geometry.attributes.position.array);

            if (box.min.z + piece.position.z > center) {
              const newPosZ = piece.position.z + delta / 100;
              piece.position.set(piece.position.x, piece.position.y, newPosZ);
            }
            piece.needsUpdate = true;
          }
        } else if (axe === 'y') {
          const modificationPiece = pieces[modificationPieceIndex];
          const modificationPieceBox = new Box3().setFromArray(
            modificationPiece?.geometry.attributes.position.array,
          );
          const center =
            (modificationPieceBox.min.y + modificationPieceBox.max.y) / 2 +
            modificationPiece.position.y;

          const pieceToMove = mappedPiecesOfUnderAssembly.filter((el) => {
            if (el.pieceIndex < indexOfFirstPieceOnAssembly) return false;

            const isPresent = pieceToApply.some((p) => p.piecesIndex.includes(el.pieceIndex));

            return !isPresent;
          });

          for (const el of pieceToMove) {
            const piece = pieces[el.pieceIndex];
            const box = new Box3().setFromArray(piece?.geometry.attributes.position.array);
            const isColliding = isPieceInCollision(modificationPiece, piece, axe);

            if (
              isColliding &&
              activePieces[el.pieceIndex].underAssembly &&
              box.min.y + piece.position.y > center
            ) {
              const newPosY = piece.position.y + delta / 100;
              piece.position.set(piece.position.x, newPosY, piece.position.z);
            }

            piece.needsUpdate = true;
          }
        }
        applyModification(
          modificationToApply,
          delta,
          changeGeometry,
          history,
          pieces,
          checkCollision,
          axe,
          modificationPieceIndex,
        );

        const pieceAlreadyChange = [modificationPieceIndex - indexOfFirstPieceOnAssembly];

        if (pieceToApply) {
          for (const el of pieceToApply) {
            if (el.axis.includes(axe)) {
              for (const element of el.piecesIndex) {
                if (!pieceAlreadyChange.includes(element)) {
                  resizeUnderAssemblyPiece(
                    indexOfFirstPieceOnAssembly + element,
                    axe,
                    pieces[indexOfFirstPieceOnAssembly + element].geometry.attributes.position
                      .array,
                    delta,
                  );
                  pieceAlreadyChange.push(element);
                }
              }
            }
          }
        }
      }
    } else {
      const pieceToApply = linkedPiecesOnAssembly?.filter((el) => {
        return el.piecesIndex.includes(pieceIndex);
      });

      console.log('linkedPiecesOnAssembly', linkedPiecesOnAssembly);

      const pieceAlreadyChange = [pieceIndex];
      const delta =
        Number.parseFloat(checkMin(size[axe])) -
        Number.parseFloat(
          getPieceSize(axe, pieces[pieceIndex].geometry.attributes.position.array, false),
        );

      if (pieceToApply)
        for (const el of pieceToApply) {
          if (el.axis.includes(axe)) {
            for (const element of el.piecesIndex) {
              if (!pieceAlreadyChange.includes(element)) {
                resize(element, axe, pieces[element].geometry.attributes.position.array, delta);
                pieceAlreadyChange.push(element);
              }
            }
          }
        }

      resize(pieceIndex, axe, pieces[pieceIndex].geometry.attributes.position.array, delta);
    }
  };

  return (
    <>
      {activePieces[pieceIndex]?.isBlocked ? (
        <BlockedContainer>
          <p>
            La pièce actuellement sélectionnée est{' '}
            <span style={{ fontWeight: 700 }}>verrouillée</span>, ce qui empêche toute{' '}
            <span style={{ fontWeight: 700 }}>modification</span> ou{' '}
            <span style={{ fontWeight: 700 }}>déplacement</span>. Pour la déverrouiller, vous devez
            cliquer sur l&apos;icône de cadenas située dans le menu des pièces. Une fois cette
            action effectuée, la pièce sera libre de toute modification.
          </p>
        </BlockedContainer>
      ) : null}
      <Row
        style={{
          justifyContent: 'space-between',
          gap: '12px',
          marginTop: '18px',
          marginBottom: '18px',
        }}
      >
        {activePieces[pieceIndex]?.blockedAxis?.includes('z') ? null : (
          <Column>
            <Label>Longueur</Label>
            <Row>
              <Input
                disabled={activePieces[pieceIndex]?.isBlocked}
                value={size.z}
                onChange={(e) => setSize({ ...size, z: e.target.value })}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') applyTransformOnAllPiece('z');
                }}
                onBlur={() => {
                  applyTransformOnAllPiece('z');
                }}
              />
            </Row>
          </Column>
        )}
        {activePieces[pieceIndex]?.blockedAxis?.includes('x') ? null : (
          <Column>
            <Label>Largeur</Label>
            <Input
              disabled={activePieces[pieceIndex]?.isBlocked}
              value={size.x}
              onChange={(e) => setSize({ ...size, x: e.target.value })}
              onKeyDown={(event) => {
                if (event.key === 'Enter') applyTransformOnAllPiece('x');
              }}
              onBlur={() => {
                applyTransformOnAllPiece('x');
              }}
            />
          </Column>
        )}
        {activePieces[pieceIndex]?.blockedAxis?.includes('y') ? null : (
          <Column>
            <Label>Hauteur</Label>
            <Row>
              <Input
                disabled={activePieces[pieceIndex]?.isBlocked}
                value={size.y}
                onChange={(e) => setSize({ ...size, y: e.target.value })}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') applyTransformOnAllPiece('y');
                }}
                onBlur={() => {
                  applyTransformOnAllPiece('y');
                }}
              />
            </Row>
          </Column>
        )}
      </Row>
      {error ? (
        <Typography variant="subtitle2" sx={{ marginBottom: '12px' }} color="error">
          {error}
        </Typography>
      ) : null}
    </>
  );
};

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const Input = styled('input')(({ hasSelect }: { hasSelect?: boolean }) => ({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  borderTopRightRadius: hasSelect ? '0px' : '8px',
  borderBottomRightRadius: hasSelect ? '0px' : '8px',
  border: '1px solid #C2C9D1',

  '-webkit-appearance': 'none',

  color: '#273135',

  '&:focus-visible': {
    outline: '1px solid #BC9A67',
  },
}));

const Select = styled('select')({
  width: '100%',
  padding: '10px 6px',
  borderRadius: '8px',
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  border: '1px solid #C2C9D1',
  borderLeftWidth: '0px',

  color: '#273135',
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Label = styled('p')({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',

  marginBottom: '12px',

  color: 'rgba(0, 0, 0, 0.5)',
});

const BlockedContainer = styled('div')({
  width: '100%',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#F7F0E7',
  padding: '12px',
  paddingTop: '6px',
  paddingBottom: '6px',
  marginTop: '12px',
});
