import { CheckCircle, ContentCopy, Delete, Edit, Share, Visibility } from '@mui/icons-material';
import { Chip, Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridSelectionModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { parse } from 'date-fns';
import { useEnv } from '../../../context/EnvProvider';
import { useUser } from '../../../context/UserProvider';
import useLoadDataList from '../../../hook/useLoadDataList';
import paths from '../../../navigation/paths';
import {
  confirmOrders,
  duplicateQuotes,
  getQuotes,
  orderQuotes,
  QuoteFile,
  QuoteStatus,
} from '../../../services/quotes';
import { ELEVATED_ROLES } from '../../../services/user';
import { handleMutation } from '../../../utils/api.utils';
import { isAdmin, isHeadOffice } from '../../../utils/user.utils';
import {
  DataRow,
  formatRow,
  getOrdersStatusComponent,
  getSpecialQueryColumns,
  ORDER,
  QUOTE,
  TO_CONFIRM,
} from './quotes.helper';

const useQuotes = (filter?: QuoteStatus[], specialQuery?: 'admin' | 'agencies') => {
  const [search, setSearch] = useState('');
  const { data, onRefresh } = useLoadDataList(() => getQuotes(specialQuery));

  const [showDocs, setShowDoc] = useState<QuoteFile[]>([]);
  const [rows, setRows] = useState<DataRow[]>([]);
  const navigate = useNavigate();
  const { homePath } = useUser();
  const [checkedQuotes, setCheckedQuotes] = useState<GridSelectionModel>([]);
  const [loading, setLoading] = useState(false);
  const [quotesToDelete, setQuotesToDelete] = useState<number[]>([]);

  const [quotePage, setQuotePage] = useState<'quote' | 'voucher'>('quote');

  const { buckets } = useEnv();
  const { user } = useUser();

  const getQuoteStatusComponent = (params: GridRenderCellParams<QuoteStatus>) => {
    if (!user) return;
    switch (params.value) {
      case 'DRAFT': {
        return <Chip label="● Brouillon" />;
      }
      case 'WAITING_HEAD_OFFICE': {
        return (
          <Chip
            label={
              ELEVATED_ROLES.includes(user.role)
                ? `● À valider${isAdmin(user) ? ' par le siège' : ''}`
                : '● En attente du siège'
            }
            sx={{ color: '#B54708', background: '#FFFAEB' }}
          />
        );
      }
      case 'WAITING': {
        return (
          <Chip
            label={isAdmin(user) ? '● À valider' : '● En attente'}
            sx={{ color: '#B54708', background: '#FFFAEB' }}
          />
        );
      }
      case 'ORDER_VALIDATED':
      case 'WAITING_ORDER': {
        return <Chip label="● Commandé" sx={{ color: '#027A48', background: '#ECFDF3' }} />;
      }
      default: {
        return <Chip label="● Devis" />;
      }
    }
  };

  const handleAction = async (
    assemblyIds: number[],
    action: (assemblyIds: number[]) => Promise<AxiosResponse<void>>,
    successToastMessage?: string,
  ) => {
    setLoading(true);
    try {
      await handleMutation(action, assemblyIds, onRefresh);
      if (successToastMessage) toast.success(successToastMessage);
    } catch {
      toast.error('Une erreur est survenue');
    }
    setLoading(false);
  };

  const columns: GridColumns<DataRow> = [
    {
      field: 'quoteId',
      headerName: 'N°Commande',
      flex: 1,
    },
    ...getSpecialQueryColumns(specialQuery),
    {
      field: 'image',
      headerName: 'Miniature',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => {
        let image = 'defaultImage';

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (params?.row?.customImage) image = params.row.customImage;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        else if (!params?.row?.customImage && params.value) image = params.value;

        return (
          <img
            src={`${buckets?.BUCKET_3D_PIECES || ''}/${image}`}
            alt="miniature"
            style={{ height: '80px', padding: '5px' }}
          />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Statut',
      flex: 1,
      renderCell: filter === ORDER ? getOrdersStatusComponent : getQuoteStatusComponent,
    },
    {
      field: 'familyRef',
      headerName: 'Référence',
      flex: 1,
    },
    {
      field: 'model',
      headerName: 'Modèle',
      flex: 1,
    },
    {
      field: 'granit',
      headerName: 'Granits',
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value as string} placement="top" arrow>
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: '100%',
              }}
            >
              {params.value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Date',
      flex: 1,
      valueGetter: (params: GridValueGetterParams<string>) => params.value,
      sortComparator: (v1, v2, param1, param2) => {
        const date1 = parse(param1.value as string, 'dd/MM/yyyy', new Date()).getTime();
        const date2 = parse(param2.value as string, 'dd/MM/yyyy', new Date()).getTime();
        return date1 - date2;
      },
    },
    {
      field: 'price',
      headerName: 'Prix de vente',
      flex: 1,
      valueGetter: (params: GridValueGetterParams<number>) =>
        `${new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR',
        }).format(params.value ?? 0)} TTC`,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 1.2,
      getActions: (params) => {
        const documentsActions =
          params.row.files.length === 0
            ? []
            : [
                <GridActionsCellItem
                  icon={<Visibility color="secondary" />}
                  label="Voir les documents"
                  onClick={() => {
                    setShowDoc(
                      params.row.files.map((el) => {
                        return {
                          ...el,
                          assemblyId: params.row.id,
                        };
                      }),
                    );
                  }}
                />,
                <GridActionsCellItem
                  icon={<ContentCopy color="secondary" />}
                  label="Dupliquer"
                  onClick={async () => handleAction([params.row.id], duplicateQuotes)}
                  showInMenu
                />,
                <GridActionsCellItem
                  icon={<CheckCircle color="secondary" />}
                  label="Commander"
                  onClick={async () => handleAction([params.row.id], orderQuotes)}
                  showInMenu
                  disabled={params.row.status !== 'QUOTE'}
                />,
                <GridActionsCellItem
                  icon={<Share color="secondary" />}
                  label="Partager le monument 3D"
                  onClick={async () => {}}
                  showInMenu
                  disabled
                />,
              ];

        const confirmOrderAction =
          (params.row.status === 'WAITING' && isAdmin(user)) ||
          (params.row.status === 'WAITING_HEAD_OFFICE' &&
            ['ADMIN', 'HEAD_OFFICE'].includes(user?.role ?? ''))
            ? [
                <GridActionsCellItem
                  icon={<CheckCircle color="secondary" />}
                  label="Valider la commande"
                  onClick={async () => handleAction([params.row.id], confirmOrders)}
                  showInMenu
                />,
              ]
            : [];

        const notOrderedActions = QUOTE.includes(params.row.status)
          ? [
              <GridActionsCellItem
                icon={<Delete color="secondary" />}
                label="Supprimer"
                onClick={() => setQuotesToDelete([params.row.id])}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<Edit color="secondary" />}
                label="Modifier"
                onClick={() =>
                  navigate(
                    `${homePath}/${paths.home.project.root}/${paths.home.project.configurator.root}/${params.row.id}`,
                  )
                }
              />,
            ]
          : [];

        return [...documentsActions, ...notOrderedActions, ...confirmOrderAction];
      },
    },
  ];

  const canDelete = useMemo(() => {
    if (checkedQuotes.length === 0) return false;

    if (isAdmin(user)) {
      return (
        rows.filter((row) => {
          return (
            checkedQuotes.includes(row.id) &&
            !['QUOTE', 'DRAFT', 'WAITING_ORDER', 'WAITING'].includes(row.status)
          );
        }).length === 0
      );
    }
    return (
      rows.filter((row) => {
        return checkedQuotes.includes(row.id) && ![...QUOTE, 'WAITING_ORDER'].includes(row.status);
      }).length === 0
    );
  }, [checkedQuotes, rows, user]);

  const canDuplicateOrOrder = useMemo(() => {
    const checkedRows = rows.filter((row) => checkedQuotes.includes(row.id));
    if (checkedRows.length === 0) return false;

    return (
      rows.filter((row) => checkedQuotes.includes(row.id) && row.step !== 'QUOTE').length === 0
    );
  }, [checkedQuotes, rows]);

  const canOrder = useMemo(() => {
    const checkedRows = rows.filter((row) => checkedQuotes.includes(row.id));
    if (checkedRows.length === 0) return false;

    return (
      rows.filter((row) => checkedQuotes.includes(row.id) && row.status !== 'QUOTE').length === 0
    );
  }, [checkedQuotes, rows]);

  const canConfirmOrders = useMemo(() => {
    const checkedRows = rows.filter((row) => checkedQuotes.includes(row.id));
    if (checkedRows.length === 0) return false;

    const isAdminUser = isAdmin(user);
    const isHeadOfficeUser = isHeadOffice(user);

    for (const row of checkedRows) {
      if (isHeadOfficeUser && row.status !== 'WAITING_HEAD_OFFICE') return false;
      if (isAdminUser && !TO_CONFIRM.includes(row.status) && row.status !== 'WAITING_ORDER')
        return false;
    }

    return true;
  }, [checkedQuotes, rows, user]);

  useEffect(() => {
    if (data) {
      setRows(
        data
          .filter((row) => (filter ? filter.includes(row.status) : true))
          .map((row) => formatRow(row))
          .filter((row) => {
            if (search) {
              return (
                row.quoteId
                  ?.toLowerCase()
                  .replace(' ', '')
                  .includes(search.toLowerCase().replace(' ', '')) ||
                row.companyName
                  ?.toLowerCase()
                  .replace(' ', '')
                  .includes(search.toLowerCase().replace(' ', '')) ||
                row.companyReason
                  ?.toLowerCase()
                  .replace(' ', '')
                  .includes(search.toLowerCase().replace(' ', '')) ||
                row.familyRef
                  ?.toLowerCase()
                  .replace(' ', '')
                  .includes(search.toLowerCase().replace(' ', '')) ||
                row.model
                  ?.toLowerCase()
                  .replace(' ', '')
                  .includes(search.toLowerCase().replace(' ', '')) ||
                row.granit
                  ?.toLowerCase()
                  .replace(' ', '')
                  .includes(search.toLowerCase().replace(' ', ''))
              );
            }
            return true;
          }),
      );
    }
  }, [data, filter, search]);

  return {
    rows,
    search,
    setSearch,
    showDocs,
    setShowDoc,
    canDelete,
    columns,
    checkedQuotes,
    setCheckedQuotes,
    handleAction,
    canDuplicateOrOrder,
    loading,
    setLoading,
    quotesToDelete,
    setQuotesToDelete,
    canConfirmOrders,
    user,
    quotePage,
    setQuotePage,
    onRefresh,
    canOrder,
  };
};

export default useQuotes;
