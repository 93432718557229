import { FC } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { Maintenance } from 'src/Screens/maintenance/Maintenance';
import RedirectAuthenticated from '../components/authentication/RedirectAuthenticated';
import RequireActivatedAccount from '../components/authentication/RequireActivatedAccount';
import RequireAuth from '../components/authentication/RequireAuth';
import AdminHome from '../Screens/admin/AdminHome';
import AdminQuotesAndOrders from '../Screens/admin/adminQuoteAndOrders/AdminQuotesAndOrders';
import AdminSettings from '../Screens/admin/adminSettings/AdminSettings';
import CatalogManagement from '../Screens/admin/catalogManagement/CatalogManagement';
import User from '../Screens/admin/users/record/User';
import Users from '../Screens/admin/users/Users';
import Agencies from '../Screens/agencies/Agencies';
import Configurator from '../Screens/Configurator';
import Home from '../Screens/Home';
import Login from '../Screens/login/Login';
import Register from '../Screens/login/register/Register';
import MySpace from '../Screens/mySpace/MySpace';
import SelectMonument from '../Screens/project/SelectMonument';
import QuotesAndOrders from '../Screens/quotesAndOrders/QuotesAndOrders';
import BackLink from './backLink';
import paths from './paths';

export const NavigationRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={paths.connexion.root} replace />} />
        <Route
          path={paths.connexion.root}
          element={
            <RedirectAuthenticated>
              <Outlet />
            </RedirectAuthenticated>
          }
        >
          <Route index element={<Login />} />
          <Route path={paths.connexion.register} element={<Register />} />
        </Route>
        <Route path={paths.maintenance.root} element={<Maintenance />} />

        <Route path={paths.home.root} element={<RequireAuth />}>
          <Route index element={<Home />} />
          <Route path={paths.home.mySpace} element={<MySpace />} />
          <Route path={paths.home.project.root} element={<RequireActivatedAccount />}>
            <Route index element={<Navigate to={paths.home.project.catalog.root} replace />} />
            <Route path={paths.home.project.catalog.root}>
              <Route index element={<SelectMonument />} />
              <Route path={paths.home.project.catalog.assemblyId} element={<SelectMonument />} />
            </Route>
            <Route path={paths.home.project.configurator.root}>
              <Route index element={<BackLink />} />
              <Route path={paths.home.project.configurator.assembly} element={<Configurator />} />
            </Route>
          </Route>
          <Route path={paths.home['quote&orders']} element={<QuotesAndOrders />} />
          <Route path={paths.home.agencies} element={<Agencies />} />
        </Route>

        <Route path={paths.admin.root} element={<RequireAuth adminAuth />}>
          <Route index element={<Navigate to={paths.admin.home.root} replace />} />
          <Route path={paths.admin.home.root}>
            <Route index element={<AdminHome />} />
            <Route path={paths.admin.home.settings} element={<AdminSettings />} />
            <Route path={paths.admin.home.users.root}>
              <Route index element={<Users />} />
              <Route path={paths.admin.home.users.user} element={<User />} />
            </Route>
            <Route path={paths.admin.home.catalogManagement} element={<CatalogManagement />} />
            <Route path={paths.home['quote&orders']} element={<AdminQuotesAndOrders />} />
            <Route path={paths.home.project.root}>
              <Route index element={<Navigate to={paths.home.project.catalog.root} replace />} />
              <Route path={paths.home.project.catalog.root}>
                <Route index element={<SelectMonument />} />
                <Route path={paths.home.project.catalog.assemblyId} element={<SelectMonument />} />
              </Route>
              <Route path={paths.home.project.configurator.root}>
                <Route index element={<BackLink />} />
                <Route path={paths.home.project.configurator.assembly} element={<Configurator />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate to={paths.home.root} replace />} />
      </Routes>
    </BrowserRouter>
  );
};
