import { api } from './api';

export type Pattern = {
  user: {
    role: string;
  };
  id: string;
  name: string;
  image: string;
  reducedImage?: string;
  price: number;
  leaderGranitEngraving: boolean;
  description: string;
  height: number;
  ratio: number;
  type?: string[];
  patternCatalog: {
    catalog: {
      name: string;
      id: string;
    };
  }[];
};

export type UpdatePattern = {
  id: string;
  name: string;
  image: string;
  reducedImage?: string;
  price: number;
  leaderGranitEngraving: boolean;
  description: string;
  height: number;
  type?: string[];
  catalogIds?: string[];
  ratio: number;
};

export const getPatterns = async (userId: string) => {
  const { data } = await api.get<Pattern[]>(`pattern/${userId}`);

  return data;
};

export const getBoPatterns = async (userId: string) => {
  const { data } = await api.get<Pattern[]>(`pattern/bo/${userId}`);

  return data;
};

export const createPattern = async (datas: Omit<UpdatePattern, 'id'>, userId: string) => {
  return api.post<void>(`/pattern/${userId}`, datas);
};

export const updatePattern = async (datas: UpdatePattern) => {
  return api.patch<void>('/pattern', datas);
};

export const deletePatterns = async (datas: string[]) => {
  return api.delete<void>('/pattern', { params: { ids: datas } });
};
