import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  styled,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import environment from 'src/environement';
import { Container, LowerCaseButton } from '../../components/common';
import { TextFieldRHF } from '../../components/forms/TextFieldRhf';
import useToggle from '../../hook/useToggle';
import paths from '../../navigation/paths';
import theme from '../../themes/theme';
import useLogin from './useLogin';

const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  flexDirection: 'column',
  width: '55%',
});

export const LoginBox = styled(Box)(({ theme: muiTheme }) => ({
  display: 'flex',
  width: '45%',
  height: '100vh',
  background: muiTheme.palette.primary.light,
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Logo = styled('img')({
  width: '80%',
});

export const LogoOccitanie = styled('img')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '5vw',
  margin: '0.5vw',
  cursor: 'pointer',
});

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  width: '50%',
  justifyContent: 'center',
});

const Login = () => {
  const {
    control,
    errors,
    handleSubmit,
    hasLoginAlert,
    rememberMe,
    setRememberMe,
    onSubmit,
    alertMessage,
  } = useLogin();

  const [showPassword, handleClickShowPassword] = useToggle();

  const navigate = useNavigate();

  return (
    <Container>
      <LoginBox>
        <Logo src="/logo_paysage.svg" />
        <LogoOccitanie
          src="/logo_occitanie.png"
          alt="occitanie website"
          onClick={() => {
            window.open('https://www.laregion.fr/', '_blank');
          }}
        />
      </LoginBox>
      <Wrapper>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h1" sx={{ textAlign: 'center' }}>
            Connexion
          </Typography>
          <TextFieldRHF
            name="email"
            control={control}
            TextFieldProps={{
              label: 'Email',
              error: Boolean(errors.email) || !!hasLoginAlert,
              helperText: errors.email?.message,
            }}
          />
          <TextFieldRHF
            name="password"
            control={control}
            TextFieldProps={{
              label: 'Mot de passe',
              error: Boolean(errors.password) || !!hasLoginAlert,
              helperText: errors.password?.message,
              type: showPassword ? 'text' : 'password',
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          {hasLoginAlert && (
            <Alert severity={hasLoginAlert} variant="filled">
              {alertMessage}
            </Alert>
          )}
          <Typography
            variant="body2"
            sx={{ textAlign: 'right', color: theme.typography.colors.light }}
          >
            <Link href="/" color="inherit">
              mot de passe oublié ?
            </Link>
          </Typography>
          <FormControlLabel
            control={<Checkbox onChange={() => setRememberMe(!rememberMe)} checked={rememberMe} />}
            label="Se souvenir de moi"
          />
          <LowerCaseButton variant="contained" type="submit" sx={{ marginTop: '20px' }}>
            Connexion
          </LowerCaseButton>
          <LowerCaseButton variant="outlined" onClick={() => navigate(paths.connexion.register)}>
            Créer mon compte
          </LowerCaseButton>
        </StyledForm>
      </Wrapper>
    </Container>
  );
};

export default Login;
