/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Button, ButtonGroup, Checkbox, Slider, styled, Typography } from '@mui/material';
import { useState } from 'react';
import { useUser } from 'src/context/UserProvider';
import { Vector3 } from 'three';

import { StateNames, States } from '../../../../Screens/Configurator';
import { isAdmin } from '../../../../utils/user.utils';
import SettingIcon from '../../../icons/SettingIcon';

type SettingsProps = {
  pieceIndex: number;
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  getValue: <T extends StateNames>(stateName: T) => States[T];
  findPointOnTheSameAxe: (axe: 'x' | 'y' | 'z') => void;
  setSelectedPoints: (seletedPoints: { pieceIndex: number; point: Float32Array }[] | null) => void;
  findPointAround: () => void;
  removeOnePiece: (pieceId: number) => void;
  movePieceOnAxe: (axe: 'x' | 'y' | 'z') => void;
  orbitRef: React.MutableRefObject<any>;
  pieces: THREE.Object3D<THREE.Event>[];
};

export const Settings = ({
  pieceIndex,
  setValue,
  getValue,
  findPointOnTheSameAxe,
  setSelectedPoints,
  findPointAround,
  removeOnePiece,
  movePieceOnAxe,
  pieces,
  orbitRef,
}: SettingsProps) => {
  const [isSetupOpen, setIsSetupOpen] = useState<boolean>(true);
  const [isPieceOptionOpen, setIsPieceOptionOpen] = useState<boolean>(true);

  const { user } = useUser();

  const moveCamera = (option: 'front' | 'back' | 'reset') => {
    if (option === 'front') {
      orbitRef.current.target = new Vector3(
        pieces[pieceIndex].position.x,
        pieces[pieceIndex].position.y,
        pieces[pieceIndex].position.z,
      );

      orbitRef.current.setAzimuthalAngle(0);
      orbitRef.current.setPolarAngle(Math.PI / 2);
      orbitRef.current.object.zoom = 3;
      orbitRef.current.object.updateProjectionMatrix();
    }
    if (option === 'back') {
      orbitRef.current.target = new Vector3(
        pieces[pieceIndex].position.x,
        pieces[pieceIndex].position.y,
        pieces[pieceIndex].position.z,
      );
      orbitRef.current.setAzimuthalAngle(Math.PI);
      orbitRef.current.setPolarAngle(Math.PI / 2);
    }
    if (option === 'reset') {
      orbitRef.current.reset();

      orbitRef.current.target = new Vector3(0, 0, 0);
      orbitRef.current.setAzimuthalAngle(0.404_891_786_285_083_4);
      orbitRef.current.setPolarAngle(1.313_982_409_374_089_5);
      orbitRef.current.object.zoom = 1;
      orbitRef.current.object.updateProjectionMatrix();
    }
  };

  return (
    <Container>
      <Header>
        <SettingIcon />
        <Typography variant="h3" style={{ fontSize: '18px' }}>
          Paramètres du configurateur
        </Typography>
      </Header>
      <BodyContainer>
        <BodyTitleContainer onClick={() => setIsSetupOpen(!isSetupOpen)}>
          <Typography variant="h5" style={{ fontSize: '14px' }}>
            Setup
          </Typography>
        </BodyTitleContainer>

        {isSetupOpen ? (
          <Body>
            <Row>
              <Typography variant="h4" style={{ fontSize: '14px' }}>
                Fond coloré
              </Typography>
              <InputColor
                type="color"
                value={getValue('options').backgroundColor}
                onChange={(e) =>
                  setValue('options', { ...getValue('options'), backgroundColor: e.target.value })
                }
              />
            </Row>

            <Row>
              <Typography variant="h4" style={{ fontSize: '14px' }}>
                Lumière
              </Typography>
              <Slider
                aria-label="Temperature"
                defaultValue={30}
                color="primary"
                value={getValue('options').light * 100}
                onChange={(e, newValue) =>
                  setValue('options', { ...getValue('options'), light: (newValue as number) / 100 })
                }
              />
            </Row>
            <Row>
              <Typography variant="h4" style={{ fontSize: '14px' }}>
                Activer le deplacement avancé
              </Typography>
              <Checkbox
                checked={getValue('advancedMove')}
                onClick={() => setValue('advancedMove', !getValue('advancedMove'))}
              />
            </Row>
            <Row>
              <Typography variant="h4" style={{ fontSize: '14px' }}>
                Activer les textures
              </Typography>
              <Checkbox
                checked={getValue('texture')}
                onClick={() => setValue('texture', !getValue('texture'))}
              />
            </Row>

            {getValue('texture') ? null : (
              <Row>
                <Typography variant="h4" style={{ fontSize: '14px' }}>
                  Activer la vue filaire
                </Typography>
                <Checkbox
                  checked={getValue('wired')}
                  onClick={() => setValue('wired', !getValue('wired'))}
                />
              </Row>
            )}
          </Body>
        ) : null}

        {isAdmin(user) ? (
          <>
            <BodyTitleContainer onClick={() => setIsPieceOptionOpen(!isPieceOptionOpen)}>
              <Typography variant="h5" style={{ fontSize: '14px' }}>
                Pièces
              </Typography>
            </BodyTitleContainer>

            {isPieceOptionOpen ? (
              <Body style={{ gap: '0px' }}>
                <Row>
                  <Typography variant="h4" style={{ fontSize: '14px' }}>
                    Activer les points
                  </Typography>
                  <Checkbox
                    checked={getValue('points')}
                    onClick={() => {
                      setValue('points', !getValue('points'));
                      setSelectedPoints(null);
                    }}
                  />
                </Row>

                <Row style={{ justifyContent: 'space-between' }}>
                  <Typography variant="h4" style={{ fontSize: '14px' }}>
                    Points sur
                  </Typography>
                  <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button style={{ fontSize: '10px' }} onClick={() => findPointOnTheSameAxe('x')}>
                      x
                    </Button>
                    <Button style={{ fontSize: '10px' }} onClick={() => findPointOnTheSameAxe('y')}>
                      y
                    </Button>
                    <Button style={{ fontSize: '10px' }} onClick={() => findPointOnTheSameAxe('z')}>
                      z
                    </Button>
                  </ButtonGroup>
                </Row>

                <Row style={{ marginTop: '4px', justifyContent: 'space-between' }}>
                  <div />
                  <Button
                    style={{ fontSize: '10px' }}
                    onClick={() => setSelectedPoints(null)}
                    variant="contained"
                  >
                    Reinitialiser les points
                  </Button>
                </Row>
                <Row
                  style={{
                    marginTop: '4px',
                    marginBottom: '12px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div />
                  <Button
                    style={{ fontSize: '10px' }}
                    onClick={() => findPointAround()}
                    variant="contained"
                  >
                    Trouver les points autour
                  </Button>
                </Row>

                <Row style={{ marginTop: '4px', justifyContent: 'space-between' }}>
                  <Typography variant="h4" style={{ fontSize: '14px' }}>
                    Aligner
                  </Typography>
                  <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button style={{ fontSize: '10px' }} onClick={() => movePieceOnAxe('x')}>
                      x
                    </Button>
                    <Button style={{ fontSize: '10px' }} onClick={() => movePieceOnAxe('y')}>
                      y
                    </Button>
                    <Button style={{ fontSize: '10px' }} onClick={() => movePieceOnAxe('z')}>
                      z
                    </Button>
                  </ButtonGroup>
                </Row>
                <Row style={{ marginTop: '4px', justifyContent: 'space-between' }}>
                  <Typography variant="h4" style={{ fontSize: '14px' }}>
                    Caméra
                  </Typography>
                  <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button style={{ fontSize: '10px' }} onClick={() => moveCamera('front')}>
                      Face
                    </Button>
                    <Button style={{ fontSize: '10px' }} onClick={() => moveCamera('back')}>
                      Derrière
                    </Button>
                    <Button style={{ fontSize: '10px' }} onClick={() => moveCamera('reset')}>
                      reset
                    </Button>
                  </ButtonGroup>
                </Row>
              </Body>
            ) : null}
          </>
        ) : null}
      </BodyContainer>
    </Container>
  );
};

const Container = styled('div')({
  width: '100%',
});

const Header = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  gap: '8px',
  padding: '24px 32px',

  backgroundColor: '#ECF7FA',
  borderRadius: '10px',
  borderBottomLeftRadius: '0px',
  borderBottomRightRadius: '0px',
});

const Body = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  padding: '24px 32px',

  gap: '12px',
});

const BodyContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  padding: '24px 32px',

  backgroundColor: 'white',
  borderRadius: '10px',
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',

  maxHeight: window.innerHeight - 300,
  overflowY: 'auto',
});

const BodyTitleContainer = styled('div')({
  borderBottom: '1px solid #dbdbdb',
  padding: '4px',
  cursor: 'pointer',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',

  alignItems: 'center',

  gap: '8px',
});

const InputColor = styled('input')({
  '-moz-color-swatch': 'none',

  appearance: 'none',
  padding: '0px',
  border: 'none',

  height: '28px',
  width: '28px',

  cursor: 'pointer',
  backgroundColor: 'transparent',

  '&::-moz-color-swatch': {
    border: '1px solid black',
    margin: '0px',
    padding: '0px',
    borderRadius: '4px',
  },
});
