export const DEFAULT_ENGRAVINGS = {
  gold: {
    coeff1: 1,
    coeff2: 2,
    coeff3: 3,
    coeff4: 4,
    coeff5: 5,
    coeff6: 6,
    coeff7: 7,
  },
  white: {
    coeff1: 1,
    coeff2: 2,
    coeff3: 3,
    coeff4: 4,
    coeff5: 5,
    coeff6: 6,
    coeff7: 7,
  },
};

export const DEFAULT_COEFFICIENTS = {
  frenchEco: 2.3,
  frenchNormal: 2.3,
  importEco: 2.3,
  importNormal: 2.3,
  patterns: 2.3,
  accessories: 2.3,
  delivery: 2.3,
  coeffFr: 1,
  coeffImport: 1,
  coeffEcoImport: 1,
  coeffEcoFr: 1,
  engraving: DEFAULT_ENGRAVINGS,
};
