import { StateNames, States } from 'src/Screens/Configurator';
import styled from 'styled-components';

type TexturesButtonsProps = {
  step: string;
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  getValue: (stateName: StateNames) => States[StateNames];
};

export const TexturesButtons = ({ step, setValue, getValue }: TexturesButtonsProps) => {
  return (
    <Container
      style={{
        width: step === 'QUOTE' ? '55vw' : '65vw',
      }}
    >
      <ButtonContainer>
        <Button
          isSelected={getValue('texture') as boolean}
          onClick={() => {
            if (!getValue('texture')) {
              setValue('texture', true);
            }
            if (getValue('wired')) setValue('wired', false);
          }}
        >
          <img
            src="https://leader-granits-frontend.s3.eu-west-3.amazonaws.com/images/CUBE+Texture+3.png"
            alt="texture"
            style={{
              width: '40px',
              height: '40px',
              marginTop: '6px',
              marginLeft: '3px',
              marginRight: '3px',
            }}
          />
        </Button>
        <Button
          isSelected={!getValue('texture') && !getValue('wired')}
          onClick={() => {
            if (getValue('texture')) {
              setValue('texture', false);
            }
            if (getValue('wired')) setValue('wired', false);
          }}
        >
          <img
            src="https://leader-granits-frontend.s3.eu-west-3.amazonaws.com/images/CUBE+blanc.png"
            alt="texture"
            style={{
              width: '40px',
              height: '40px',
              marginTop: '6px',
              marginLeft: '3px',
              marginRight: '3px',
            }}
          />
        </Button>
        <Button
          isSelected={getValue('wired') as boolean}
          onClick={() => {
            setValue('texture', false);
            if (!getValue('wired')) {
              setValue('wired', true);
            }
          }}
        >
          <img
            src="https://leader-granits-frontend.s3.eu-west-3.amazonaws.com/images/CUBE+filaire+pointille%CC%81.png"
            alt="texture"
            style={{
              width: '40px',
              height: '40px',
              marginTop: '6px',
              marginLeft: '3px',
              marginRight: '3px',
            }}
          />
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
  padding: '20px',
  pointerEvents: 'none',
  bottom: 0,
  left: 0,
  zIndex: 100,
});

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',
});

type ButtonType = {
  isSelected: boolean;
};
const Button = styled.div<ButtonType>`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? '#548797' : 'white')};
  padding: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 999px;
  box-shadow: ${(props) =>
    props.isSelected ? 'inset 0 -10px 10px -10px white, inset 0 10px 10px -10px black' : ''};
`;
