/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ChevronRight, Edit, ExpandMore, Share, Visibility } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useUser } from 'src/context/UserProvider';
import { PieceDetail } from 'src/hook/useConfigurator';
import { getQuoteLine } from 'src/utils/configurator.utils';

import { Box3, Shape, Vector3 } from 'three';
import { LowerCaseButton } from '../../components/common';
import StyledSwitch from '../../components/forms/StyledSwitch';
import DimensionalSettingsIcon from '../../components/icons/DimensionalSettingsIcon';
import useHandleClickOutside from '../../hook/useHandleClickOutside';
import useToggle from '../../hook/useToggle';
import { Assembly } from '../../services/assemblies';
import { ModalType, StateNames, States } from '../Configurator';

export const getColorName = (color: string) => {
  if (color === '#ECB86B') return 'Or';
  if (color === '#FFFFFF') return 'Blanc';
  if (color === '#101828') return 'Noir';
};

export const getFontName = (font: string) => {
  if (font === 'arial') return 'Baton';
  if (font === 'times') return 'Romaine';
  if (font === 'england') return 'Anglaise';
};
interface IRecapProps {
  assembly: Assembly;
  setModal: (modalType: ModalType) => void;
  submit: ({
    detailed,
    discount,
    hasToBeOrdered,
    installations,
  }: {
    detailed?: boolean;
    discount?: number;
    hasToBeOrdered?: boolean;
    installations?: { price: number; name: string }[];
  }) => void;
  getAssemblyDetails: () => {
    pieces: PieceDetail[];
    accesories: PieceDetail[];
    steles: PieceDetail[];
    platings: PieceDetail[];
    patterns: {
      scale: {
        x: number;
        y: number;
      };
    }[];
    engravings: any[];
    underAssemblies: {
      pieces: PieceDetail[];
      underAssemblyName: string;
      underAssemblyUUID: string;
      x: number;
      y: number;
      z: number;
      size: number;
    }[];
  };
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  setPieceIndex: (pieceIndex: number) => void;
  centerCamera: () => void;
  getAssemblySize: (wihoutSole?: boolean) => { depth: number; width: number };
  isLoading: boolean;
  activePieces: any[];
  platings: any[];
  steles: any[];
  activeInstallations: { price: number; name: string }[];
  setActiveInstallations: (activeInstallations: { price: number; name: string }[]) => void;
}

const priceFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

const getPurchasePrice = (details: PieceDetail[]) => {
  return details.reduce((sum, piece) => sum + piece.htPrice, 0);
};

const Recap = ({
  assembly,
  setModal,
  submit,
  getAssemblyDetails,
  setValue,
  setPieceIndex,
  centerCamera,
  getAssemblySize,
  isLoading,
  activePieces,
  platings,
  steles,
  activeInstallations,
  setActiveInstallations,
}: IRecapProps) => {
  const { user } = useUser();
  const [assemblyDetails, setAssemblyDetails] = useState<{
    pieces: PieceDetail[];
    accesories: PieceDetail[];
    steles: PieceDetail[];
    platings: PieceDetail[];
    engravings: any[];
    underAssemblies: {
      pieces: PieceDetail[];
      underAssemblyName: string;
      underAssemblyUUID: string;
      x: number;
      y: number;
      z: number;
      size: number;
    }[];
    patterns: any[];
  }>();

  useEffect(() => {
    setAssemblyDetails(getAssemblyDetails());
  }, [getAssemblyDetails]);

  const simplesPieces = {
    pieces: assemblyDetails?.pieces,
    steles: assemblyDetails?.steles,
    platings: assemblyDetails?.platings,
  };

  const allPieces = useMemo(() => {
    const allPiecesTmp = [];
    for (const piece of assemblyDetails?.pieces ?? []) {
      allPiecesTmp.push(piece);
    }
    for (const accesory of assemblyDetails?.accesories ?? []) {
      allPiecesTmp.push(accesory);
    }
    for (const stele of assemblyDetails?.steles ?? []) {
      allPiecesTmp.push(stele);
    }
    for (const plating of assemblyDetails?.platings ?? []) {
      allPiecesTmp.push(plating);
    }
    for (const underAssembly of assemblyDetails?.underAssemblies ?? []) {
      for (const piece of underAssembly.pieces) {
        allPiecesTmp.push(piece);
      }
    }
    for (const engraving of assemblyDetails?.engravings ?? []) {
      allPiecesTmp.push(engraving);
    }
    for (const pattern of assemblyDetails?.patterns ?? []) {
      allPiecesTmp.push(pattern);
    }

    return allPiecesTmp;
  }, [assemblyDetails]);

  const [showEditActions, toggleShowEditActions] = useToggle();
  const actionsSelectionRef = useRef<HTMLDivElement>(null);
  const buttonActionsSelectionRef = useRef<HTMLButtonElement>(null);

  useHandleClickOutside(actionsSelectionRef, toggleShowEditActions, buttonActionsSelectionRef);

  const [showVisibilityActions, toggleShowVisibilityActions] = useToggle();
  const visibilityDialogRef = useRef<HTMLDivElement>(null);
  const buttonVisibilityDialogRef = useRef<HTMLButtonElement>(null);

  useHandleClickOutside(
    visibilityDialogRef,
    toggleShowVisibilityActions,
    buttonVisibilityDialogRef,
  );

  const [detailedQuote, setDetailedQuote] = useState(true);
  const [showPurchasePrice, setShowPurchasePrice] = useState(false);
  const [displayPurchasePrice, setDisplayPurchasePrice] = useState(false);

  useEffect(() => {
    if (typeof user?.quoteSettings?.simplifiedQuote === 'boolean')
      setDetailedQuote(!user?.quoteSettings?.simplifiedQuote);
  }, [user]);

  const getFormatedPrice = (price: string) => {
    return Number.isNaN(Number.parseFloat(price)) ? 0 : Number.parseFloat(price);
  };
  const detailKeys = Object.keys(simplesPieces) as (keyof typeof simplesPieces)[];

  const hasPieceOfTypeSole = useCallback(() => {
    return activePieces.some((obj) => obj && obj.type === 'SOLE');
  }, [activePieces]);

  const hasPiceOtherThanSole = useCallback(() => {
    if (steles && steles.length > 0) return true;
    if (platings && platings.length > 0) return true;

    return activePieces.some((obj) => obj && obj.type !== 'SOLE');
  }, [activePieces, platings, steles]);

  const soleInstallationPrice = useMemo(() => {
    let installation = 0;

    if (hasPieceOfTypeSole() && user?.quoteSettings?.activeSoleInstallation) {
      installation = user.quoteSettings?.soleInstallation ?? 0;
    }
    return Number.parseFloat(String(installation));
  }, [
    hasPieceOfTypeSole,
    user?.quoteSettings?.activeSoleInstallation,
    user?.quoteSettings?.soleInstallation,
  ]);

  const installationPrice = useMemo(() => {
    let installation = 0;

    if (hasPiceOtherThanSole()) {
      const assemblySizeWithoutSole = getAssemblySize(true);

      if (assembly.type === 'COLUMBARIUM' && user?.quoteSettings?.activeSpecialInstallation) {
        return Number.parseFloat(String(user.quoteSettings?.specialInstallation ?? 0));
      }
      if (
        user?.quoteSettings?.activeCineraryInstallation &&
        assemblySizeWithoutSole.depth * assemblySizeWithoutSole.width <= 1
      ) {
        installation += user.quoteSettings?.cineraryInstallation ?? 0;
      } else if (
        user?.quoteSettings?.activeMonumentInstallation &&
        assemblySizeWithoutSole.depth * assemblySizeWithoutSole.width <= 2.76 &&
        assemblySizeWithoutSole.depth * assemblySizeWithoutSole.width >= 1
      ) {
        installation += user.quoteSettings?.monumentInstallation ?? 0;
      } else if (
        user?.quoteSettings?.activeSpecialInstallation &&
        assemblySizeWithoutSole.depth * assemblySizeWithoutSole.width >= 2.76
      ) {
        installation += user.quoteSettings?.specialInstallation ?? 0;
      }
    }
    return Number.parseFloat(String(installation));
  }, [
    assembly.type,
    getAssemblySize,
    hasPiceOtherThanSole,
    user?.quoteSettings?.activeCineraryInstallation,
    user?.quoteSettings?.activeMonumentInstallation,
    user?.quoteSettings?.activeSpecialInstallation,
    user?.quoteSettings?.cineraryInstallation,
    user?.quoteSettings?.monumentInstallation,
    user?.quoteSettings?.specialInstallation,
  ]);

  useEffect(() => {
    const installations = [];

    if (installationPrice > 0 && !user?.quoteSettings?.addInstallationToMonumentPrice) {
      installations.push({ price: installationPrice, name: 'Pose' });
    }
    if (soleInstallationPrice > 0 && !user?.quoteSettings?.addInstallationToSolePrice) {
      installations.push({ price: soleInstallationPrice, name: 'Pose de la semelle' });
    }

    setActiveInstallations(installations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    installationPrice,
    soleInstallationPrice,
    user?.quoteSettings?.addInstallationToMonumentPrice,
    user?.quoteSettings?.addInstallationToSolePrice,
  ]);

  const additionalServicesPrice = useMemo(() => {
    return (
      assembly.quote?.additionalServicesOnQuote?.reduce((sum, el) => {
        if (typeof el.additionalService.price === 'string')
          return sum + Number.parseFloat(el.additionalService.price);
        return sum + el.additionalService.price;
      }, 0) ?? 0
    );
  }, [assembly.quote?.additionalServicesOnQuote]);

  const getSalePrice = (details: PieceDetail[]) => {
    const piecesPrice = details.reduce((sum, piece) => sum + piece.ttcPrice, 0);
    const installationsPrices = activeInstallations.reduce((sum, obj) => sum + obj.price, 0);

    return piecesPrice + additionalServicesPrice + installationsPrices;
  };

  const totalWeight = useMemo(() => {
    if (assemblyDetails) {
      const quoteLines = getQuoteLine(assemblyDetails);

      return quoteLines.reduce((sum, obj) => sum + (obj.weight ?? 0) / 1000, 0);
    }
    return 0;
  }, [assemblyDetails]);

  const delivery = useMemo(() => {
    let deliveryPrice = 0;

    if (assemblyDetails && !user?.quoteSettings?.postageDue) {
      if (showPurchasePrice) {
        if (!user?.quoteSettings?.activeCustomDelivery && user?.deliveryCost) {
          deliveryPrice = Math.ceil(Number.parseFloat(user?.deliveryCost.price) * totalWeight);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        deliveryPrice =
          (user?.quoteSettings?.activeCustomDelivery
            ? user?.quoteSettings?.customDelivery ?? 0
            : Number.parseFloat(user?.deliveryCost?.price ?? '0') *
              (user?.userCoefficients?.delivery ?? 1)) * totalWeight;
      }
    }

    return deliveryPrice;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assemblyDetails, user?.quoteSettings, showPurchasePrice, user]);

  const totalSalePrice = useMemo(() => {
    const salePrice = getSalePrice(allPieces);
    return salePrice + delivery;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPieces, delivery]);

  const totalPurchasePrice = useMemo(() => {
    const salePrice = getPurchasePrice(allPieces);

    return salePrice + delivery;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPieces, delivery]);

  const [finalPrice, setFinalPrice] = useState<string>('');
  const [discount, setDiscount] = useState<string>();
  const [percentage, setPercentage] = useState<string>();
  const [isEditingFinalPrice, setIsEditingFinalPrice] = useState<boolean>(false);
  const [isEditingDiscount, setIsEditingDiscount] = useState<boolean>(false);
  const [isEditingDiscountPercentage, setIsEditingDiscountPercentage] = useState<boolean>(false);
  const [openedPattern, setOpenedPattern] = useState<string>();

  const [openUnderAssemblies, setOpenUnderAssemblies] = useState<boolean[]>([]);

  useEffect(() => {
    if (assemblyDetails) {
      setOpenUnderAssemblies(assemblyDetails.underAssemblies.map(() => false));
    }
  }, [assemblyDetails]);

  useEffect(() => {
    if (showPurchasePrice) {
      setFinalPrice(totalPurchasePrice.toFixed(2));
    } else if (Number.parseFloat(discount ?? '')) {
      setFinalPrice((totalSalePrice - Number.parseFloat(discount ?? '')).toFixed(2));
    } else {
      setFinalPrice(totalSalePrice.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount, showPurchasePrice, totalPurchasePrice, totalSalePrice]);

  useEffect(() => {
    const discountTmp = Number.parseFloat(discount ?? '');
    if (discountTmp > 0) {
      setPercentage(((discountTmp / totalSalePrice) * 100).toFixed(2));
    }
  }, [discount, totalSalePrice]);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return `${text.slice(0, Math.max(0, maxLength))}...`;
    }
    return text;
  };

  const getMonumentPrice = () => {
    const monumentPieces = [];

    for (const piece of assemblyDetails?.pieces ?? []) {
      monumentPieces.push(piece);
    }
    for (const stele of assemblyDetails?.steles ?? []) {
      monumentPieces.push(stele);
    }
    for (const plating of assemblyDetails?.platings ?? []) {
      monumentPieces.push(plating);
    }
    for (const underAssembly of assemblyDetails?.underAssemblies ?? []) {
      for (const piece of underAssembly.pieces) {
        monumentPieces.push(piece);
      }
    }

    if (showPurchasePrice) return priceFormatter.format(getPurchasePrice(monumentPieces));

    let monumentSalePrice = monumentPieces.reduce((sum, piece) => sum + piece.ttcPrice, 0);

    if (!user?.quoteSettings?.displayDelivery) {
      monumentSalePrice +=
        delivery *
        (monumentPieces.reduce(
          (sum, piece) => sum + Math.round((piece.granit?.weight ?? 0) * piece.size),
          0,
        ) /
          (totalWeight * 1000));
    }

    if (
      user?.quoteSettings?.activeMonumentInstallation &&
      user?.quoteSettings?.addInstallationToMonumentPrice &&
      hasPiceOtherThanSole()
    ) {
      const monumentInstallationPrice = activeInstallations.find((el) => el.name === 'Pose')?.price;
      monumentSalePrice += monumentInstallationPrice ?? 0;
    }

    if (
      user?.quoteSettings?.activeSoleInstallation &&
      user?.quoteSettings?.addInstallationToSolePrice &&
      hasPieceOfTypeSole()
    ) {
      const monumentInstallationPrice = activeInstallations.find(
        (el) => el.name === 'Pose de la semelle',
      )?.price;
      monumentSalePrice += monumentInstallationPrice ?? 0;
    }

    return priceFormatter.format(monumentSalePrice);
  };

  const getAccessoriesPrice = () => {
    const accessories = [];
    for (const piece of assemblyDetails?.accesories ?? []) {
      accessories.push(piece);
    }

    if (showPurchasePrice) return priceFormatter.format(getPurchasePrice(accessories));

    let monumentSalePrice = accessories.reduce((sum, piece) => sum + piece.ttcPrice, 0);

    if (!user?.quoteSettings?.displayDelivery) {
      monumentSalePrice +=
        delivery *
        (accessories.reduce(
          (sum, piece) => sum + Math.round((piece.granit?.weight ?? 0) * piece.size),
          0,
        ) /
          (totalWeight * 1000));
    }

    return priceFormatter.format(monumentSalePrice);
  };

  const traductAlign = (align: 'left' | 'right' | 'center') => {
    switch (align) {
      case 'right': {
        return 'droite';
      }
      case 'center': {
        return 'centre';
      }
      default: {
        return 'gauche';
      }
    }
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderTextWrapper>
          {assembly.quote?.cemetary && (
            <Line>
              <HeaderTypography>CIMETIÈRE</HeaderTypography>
              <Typography sx={{ fontSize: '12px', lineHeight: '24px', fontWeight: 600 }}>
                {assembly.quote?.cemetary}
              </Typography>
            </Line>
          )}
          {assembly.quote?.concessionName && (
            <Line>
              <HeaderTypography>N° DE CONCESSION</HeaderTypography>
              <Typography sx={{ fontSize: '12px', lineHeight: '24px', fontWeight: 600 }}>
                {assembly.quote?.concessionName}
              </Typography>
            </Line>
          )}
        </HeaderTextWrapper>
        {assembly.quote?.familyRef && (
          <HeaderTextWrapper>
            <HeaderTypography align="right">DEVIS RÉFÉRENCE</HeaderTypography>
            <Typography align="right" variant="h2">
              {assembly.quote?.familyRef}
            </Typography>
          </HeaderTextWrapper>
        )}
      </HeaderWrapper>
      <RecapWrapper>
        {(simplesPieces.pieces && simplesPieces.pieces.length > 0) ||
        (simplesPieces.platings && simplesPieces.platings.length > 0) ||
        (simplesPieces.steles && simplesPieces.steles.length > 0) ||
        (assemblyDetails?.underAssemblies && assemblyDetails?.underAssemblies.length > 0) ? (
          <>
            <RecapHeader>
              <Typography variant="inherit">Monument</Typography>
              {detailedQuote ? (
                <Typography variant="inherit">{getMonumentPrice()}</Typography>
              ) : null}
            </RecapHeader>

            {assemblyDetails?.underAssemblies.map((underAssembly, index) => {
              return (
                <>
                  <RecapItem
                    style={{ cursor: 'pointer', gap: 4, alignItems: 'center' }}
                    onClick={() => {
                      setOpenUnderAssemblies(
                        openUnderAssemblies.map((el, elIndex) => {
                          if (elIndex === index) return !el;
                          return el;
                        }),
                      );
                    }}
                  >
                    {openUnderAssemblies[index] ? <ExpandMore /> : <ChevronRight />}
                    <Typography
                      variant="inherit"
                      textTransform="uppercase"
                      width="60%"
                      style={{ fontSize: 14 }}
                    >
                      {underAssembly.underAssemblyName}
                    </Typography>
                    <Typography variant="inherit" style={{ fontSize: 14 }}>
                      {Math.floor(underAssembly.z * 100)} x {Math.floor(underAssembly.x * 100)} x{' '}
                      {Math.floor(underAssembly.y * 100)}
                      ht
                    </Typography>
                  </RecapItem>

                  {openUnderAssemblies[index]
                    ? underAssembly.pieces.map((piece) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <RecapItem key={`${piece.name}_${index}`} style={{ padding: 0 }}>
                            <Row style={{ width: '55%', marginLeft: '10%' }}>
                              <Typography
                                variant="inherit"
                                textTransform="uppercase"
                                style={{ fontSize: 12 }}
                              >
                                {piece.name}
                              </Typography>
                              -
                              <Typography
                                variant="inherit"
                                textTransform="uppercase"
                                style={{ fontSize: 12 }}
                              >
                                {piece.granit?.name}
                              </Typography>
                            </Row>
                            <Typography variant="inherit" style={{ fontSize: 12 }}>
                              {Math.round(piece.z * 100)} x {Math.round(piece.x * 100)} x{' '}
                              {Math.round(piece.y * 100)}
                              ht
                            </Typography>
                          </RecapItem>
                        );
                      })
                    : null}
                </>
              );
            })}
            {detailKeys.map((key) =>
              simplesPieces[key]?.map((el: PieceDetail, index: number) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <RecapItem key={`${el.name}_${index}`} style={{ fontSize: 14 }}>
                    <Row style={{ width: '64%' }}>
                      <Typography variant="inherit" textTransform="uppercase">
                        {el.name}
                      </Typography>
                      -
                      <Typography variant="inherit" textTransform="uppercase">
                        {el.granit?.name}
                      </Typography>
                    </Row>
                    <Typography variant="inherit" style={{ fontSize: 14 }}>
                      {Math.round(el.z * 100)} x {Math.round(el.x * 100)} x {Math.round(el.y * 100)}
                      ht
                    </Typography>
                  </RecapItem>
                );
              }),
            )}
          </>
        ) : null}

        {assemblyDetails?.accesories && assemblyDetails?.accesories.length > 0 ? (
          <>
            <RecapHeader>
              <Typography variant="inherit">Accessoires</Typography>
              {detailedQuote ? (
                <Typography variant="inherit">{getAccessoriesPrice()}</Typography>
              ) : null}
            </RecapHeader>

            {assemblyDetails?.accesories.map((accesory: any, index: number) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <RecapItem key={`accesory_${index}`} style={{ fontSize: 14 }}>
                  <Row style={{ width: '64%' }}>
                    <Typography variant="inherit" textTransform="uppercase">
                      {accesory.name} - {accesory.granit?.name}
                    </Typography>
                  </Row>

                  <Typography variant="inherit" style={{ fontSize: 14 }}>
                    {Math.round(accesory.z * 100)} x {Math.round(accesory.x * 100)} x{' '}
                    {Math.round(accesory.y * 100)}
                    ht
                  </Typography>
                </RecapItem>
              );
            })}
          </>
        ) : null}
        {assemblyDetails?.patterns && assemblyDetails?.patterns.length > 0 ? (
          <>
            <RecapHeader>
              <Typography variant="inherit">Motifs</Typography>
              {detailedQuote ? (
                <Typography variant="inherit">
                  {assemblyDetails?.patterns
                    /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
                    .reduce(
                      (total, obj) =>
                        (total as number) +
                        (showPurchasePrice ? (obj.htPrice as number) : (obj.ttcPrice as number)),
                      0,
                    )
                    .toFixed(2)}{' '}
                  €
                </Typography>
              ) : null}
            </RecapHeader>

            {assemblyDetails?.patterns.map((pattern: any, index: number) => {
              return (
                <>
                  <RecapItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`pattern_${index}`}
                    style={{ fontSize: 14, cursor: 'pointer' }}
                    onClick={() =>
                      setOpenedPattern(openedPattern === pattern.uuid ? undefined : pattern.uuid)
                    }
                  >
                    {openedPattern === pattern.uuid ? <ExpandMore /> : <ChevronRight />}
                    <Typography
                      variant="inherit"
                      textTransform="uppercase"
                      width="55%"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {pattern.name}
                    </Typography>
                    <Typography variant="inherit" style={{ fontSize: 14 }}>
                      {pattern.scale.x} x {pattern.scale.y}
                      ht
                    </Typography>
                  </RecapItem>
                  {openedPattern === pattern.uuid ? (
                    <RecapItem
                      style={{ fontSize: 12, paddingLeft: 32 }}
                      onClick={() => setOpenedPattern(pattern.uuid)}
                    >
                      <Typography variant="inherit" textTransform="uppercase" width="100%">
                        {pattern.description}
                      </Typography>
                    </RecapItem>
                  ) : null}
                </>
              );
            })}
          </>
        ) : null}

        {assemblyDetails?.engravings && assemblyDetails?.engravings.length > 0 ? (
          <>
            <RecapHeader>
              <Typography variant="inherit">Gravure</Typography>
              {detailedQuote ? (
                <Typography variant="inherit">
                  {detailedQuote ? (
                    <Typography variant="inherit">
                      {assemblyDetails?.engravings
                        /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
                        .reduce(
                          (total, obj) =>
                            (total as number) +
                            (showPurchasePrice
                              ? (obj.htPrice as number)
                              : (obj.ttcPrice as number)),
                          0,
                        )
                        .toFixed(2)}{' '}
                      €
                    </Typography>
                  ) : null}
                </Typography>
              ) : null}
            </RecapHeader>

            {assemblyDetails?.engravings.map((engraving: any, index: number) => {
              return (
                <>
                  <RecapItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`engraving_${index}`}
                    style={{ fontSize: 14, cursor: 'pointer' }}
                    onClick={() =>
                      setOpenedPattern(
                        openedPattern === engraving.uuid ? undefined : engraving.uuid,
                      )
                    }
                  >
                    {openedPattern === engraving.uuid ? <ExpandMore /> : <ChevronRight />}
                    <Typography
                      variant="inherit"
                      textTransform="uppercase"
                      width="55%"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {truncateText(engraving.text, 15)}
                    </Typography>
                    <Typography variant="inherit" style={{ fontSize: 14 }}>
                      {engraving.fontSize}
                      cm
                    </Typography>
                  </RecapItem>
                  {openedPattern === engraving.uuid ? (
                    <RecapItem
                      style={{ fontSize: 12, paddingLeft: 32 }}
                      onClick={() => setOpenedPattern(engraving.uuid)}
                    >
                      <div>
                        <Typography variant="inherit" width="100%">
                          Couleur: {getColorName(engraving.color)}
                        </Typography>
                        <Typography variant="inherit" width="100%">
                          Police: {getFontName(engraving.fontFamily)}
                        </Typography>
                        <Typography variant="inherit" width="100%">
                          Espacement: {engraving.letterSpacing} cm
                        </Typography>
                        <Typography variant="inherit" width="100%">
                          Alignement: {traductAlign(engraving.align)}
                        </Typography>
                      </div>
                    </RecapItem>
                  ) : null}
                </>
              );
            })}
          </>
        ) : null}

        {showPurchasePrice
          ? null
          : activeInstallations.map((installation) => {
              return (
                <RecapHeader sx={{ marginBottom: '22px' }}>
                  <Typography variant="inherit">{installation.name}</Typography>
                  {detailedQuote ? (
                    <Typography variant="inherit">
                      {priceFormatter.format(installation.price)}
                    </Typography>
                  ) : null}
                </RecapHeader>
              );
            })}

        {!showPurchasePrice && additionalServicesPrice ? (
          <>
            <RecapHeader>
              <Typography variant="inherit">Travaux supplémentaires</Typography>
              {detailedQuote ? (
                <Typography variant="inherit">
                  {priceFormatter.format(additionalServicesPrice)}
                </Typography>
              ) : null}

              {/* assembly.quote?.additionalServicesOnQuote */}
            </RecapHeader>
            {assembly.quote?.additionalServicesOnQuote?.map((additionalServicesOnQuote: any) => {
              return (
                <RecapItem>
                  <Typography variant="inherit" textTransform="uppercase" sx={{ fontSize: '14px' }}>
                    {additionalServicesOnQuote?.additionalService.name}
                  </Typography>
                </RecapItem>
              );
            })}
          </>
        ) : null}

        {(user?.quoteSettings?.displayDelivery || showPurchasePrice) &&
        !user?.quoteSettings?.postageDue ? (
          <RecapHeader>
            <Typography variant="inherit">Livraison</Typography>
            {detailedQuote ? (
              <Typography variant="inherit">{priceFormatter.format(delivery)}</Typography>
            ) : null}
          </RecapHeader>
        ) : null}
      </RecapWrapper>

      <ActionsWrapper>
        <ActionsButton
          color="secondary"
          onClick={toggleShowEditActions}
          ref={buttonActionsSelectionRef}
        >
          {showEditActions && (
            <ActionSelection ref={actionsSelectionRef}>
              <ActionSelectionText noWrap onClick={() => setModal('installations')}>
                Modifier les forfaits pose
              </ActionSelectionText>
              <ActionSelectionText noWrap onClick={() => setModal('additionalServices')}>
                Ajouter un travail supplémentaire
              </ActionSelectionText>
              <ActionSelectionText noWrap onClick={() => setModal('editInformations')}>
                Modifier les informations client
              </ActionSelectionText>
              <ActionSelectionText noWrap onClick={() => setDiscount('0')}>
                Réinitialiser les prix
              </ActionSelectionText>
              <ActionSelectionText
                noWrap
                sx={{ borderBottom: 'none' }}
                onClick={() => setModal('quoteImage')}
              >
                Changer l&apos;image
              </ActionSelectionText>
            </ActionSelection>
          )}
          <Edit />
        </ActionsButton>
        <ActionsButton color="secondary" disabled>
          <DimensionalSettingsIcon />
        </ActionsButton>
        <ActionsButton
          color="secondary"
          onClick={toggleShowVisibilityActions}
          ref={buttonVisibilityDialogRef}
        >
          {showVisibilityActions && (
            <ActionSelection ref={visibilityDialogRef} onClick={(event) => event.stopPropagation()}>
              <StyledControlLabel
                control={
                  <StyledSwitch
                    color="secondary"
                    checked={detailedQuote}
                    onChange={(event) => setDetailedQuote(event.target.checked)}
                  />
                }
                label={<Typography noWrap>Afficher le détail du devis</Typography>}
              />
              {displayPurchasePrice ? (
                <StyledControlLabel
                  control={
                    <StyledSwitch
                      color="secondary"
                      checked={showPurchasePrice}
                      onChange={(event) => {
                        setIsEditingFinalPrice(false);
                        setShowPurchasePrice(event.target.checked);
                      }}
                    />
                  }
                  label={<Typography noWrap>Afficher le prix d’achat</Typography>}
                  sx={{ borderBottom: 'none' }}
                />
              ) : (
                <StyledControlLabel
                  control={
                    <StyledSwitch
                      color="secondary"
                      checked={displayPurchasePrice}
                      onChange={(event) => setDisplayPurchasePrice(event.target.checked)}
                    />
                  }
                  label={<Typography noWrap>Afficher le bouton du prix d’achat</Typography>}
                  sx={{ borderBottom: 'none' }}
                />
              )}
            </ActionSelection>
          )}
          <Visibility />
        </ActionsButton>
        <ActionsButton color="secondary" disabled>
          <Share />
        </ActionsButton>
      </ActionsWrapper>
      <div style={{ width: '100%' }}>
        {Number.parseFloat(discount ?? '') > 0 || discount === '' ? (
          <Footer style={{ padding: '10px', paddingBottom: '0px' }}>
            <Typography sx={{ color: '#98A2B3' }} align="right">
              Remise
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 24 }}>
              {isEditingDiscount ? (
                <TextField
                  id="outlined-basic"
                  label="Remise"
                  variant="outlined"
                  value={discount}
                  style={{ width: '150px' }}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setIsEditingDiscount(false);
                    }
                  }}
                  onBlur={() => {
                    setIsEditingDiscount(false);
                  }}
                />
              ) : (
                <Typography
                  sx={{ color: '#98A2B3' }}
                  align="right"
                  onClick={() => {
                    if (!showPurchasePrice) setIsEditingDiscount(true);
                  }}
                >
                  {Number.parseFloat(discount ?? '').toFixed(2)} €
                </Typography>
              )}
              {isEditingDiscountPercentage ? (
                <TextField
                  id="outlined-basic"
                  label="Remise %"
                  variant="outlined"
                  value={percentage}
                  style={{ width: '150px' }}
                  onChange={(e) => {
                    setPercentage(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setIsEditingDiscountPercentage(false);
                      const percentageTmp = Number.parseFloat(percentage ?? '');
                      if (percentageTmp && percentageTmp >= 0) {
                        setDiscount((totalSalePrice * (percentageTmp / 100)).toFixed(2));
                      }
                    }
                  }}
                  onBlur={() => {
                    setIsEditingDiscountPercentage(false);
                    const percentageTmp = Number.parseFloat(percentage ?? '');
                    if (percentageTmp && percentageTmp >= 0) {
                      setDiscount((totalSalePrice * (percentageTmp / 100)).toFixed(2));
                    }
                  }}
                />
              ) : (
                <Typography
                  sx={{ color: '#98A2B3' }}
                  align="right"
                  onClick={() => {
                    if (!showPurchasePrice) setIsEditingDiscountPercentage(true);
                  }}
                >
                  {Number.parseFloat(percentage ?? '')} %
                </Typography>
              )}
            </div>
          </Footer>
        ) : null}
        <Footer>
          {isEditingFinalPrice ? (
            <TextField
              id="outlined-basic"
              label="Prix total"
              variant="outlined"
              value={finalPrice}
              style={{ width: '150px' }}
              onChange={(e) => {
                setFinalPrice(e.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  const discountTmp = (Number.parseFloat(finalPrice) - totalSalePrice) * -1;
                  setDiscount(String(discountTmp));
                  setIsEditingFinalPrice(false);
                }
              }}
              onBlur={() => {
                const discountTmp = (Number.parseFloat(finalPrice) - totalSalePrice) * -1;
                setDiscount(String(discountTmp));
                setIsEditingFinalPrice(false);
              }}
            />
          ) : (
            <Box component="div">
              <Typography sx={{ color: '#98A2B3' }} align="right">
                TOTAL
              </Typography>
              <Typography
                variant="h2"
                align="right"
                onClick={() => {
                  if (!showPurchasePrice) setIsEditingFinalPrice(true);
                }}
              >
                {showPurchasePrice
                  ? priceFormatter.format(getFormatedPrice(String(totalPurchasePrice)))
                  : priceFormatter.format(getFormatedPrice(finalPrice))}{' '}
                {showPurchasePrice ? 'HT' : 'TTC'}
              </Typography>
            </Box>
          )}
          <LowerCaseButton
            sx={{
              background: 'white',
              color: 'black',
              boxShadow: ' 0px 0.5px 4px rgba(0, 0, 0, 0.15)',
            }}
            onClick={() => {
              submit({
                detailed: detailedQuote,
                discount: Number.parseFloat(discount ?? '') * -1,
                hasToBeOrdered: true,
                installations: activeInstallations,
              });
            }}
          >
            {isLoading ? <CircularProgress size={12} color="inherit" /> : 'Commander'}
          </LowerCaseButton>
          <LowerCaseButton
            variant="contained"
            disabled={isLoading}
            onClick={() => {
              submit({
                detailed: detailedQuote,
                discount: Number.parseFloat(discount ?? '') * -1,
                installations: activeInstallations,
              });
            }}
          >
            {isLoading ? <CircularProgress size={12} color="inherit" /> : 'Enregistrer'}
          </LowerCaseButton>
        </Footer>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: '24px',
  overflowY: 'auto',
});

const Footer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 40px 32px',
  background: '#F9FAFB',
  width: '100%',
  position: 'relative',
});

const HeaderTypography = styled(Typography)({
  fontWeight: 700,
  color: '#BC9A67',
  fontSize: 12,
  lineHeight: '24px',
});

const HeaderWrapper = styled(Box)({
  width: '100%',
  padding: '26px',
  display: 'flex',
  backgroundColor: '#FFFCF6',
});

const HeaderTextWrapper = styled(Box)({
  flex: 1,
  height: '100%',
  alignItems: 'center',
});

const Line = styled(Box)({
  display: 'flex',
  gap: '8px',
});

const ActionsWrapper = styled(Box)({
  display: 'flex',
  gap: '24px',
  boxShadow: '-4px 0px 15px rgba(0, 0, 0, 0.1)',
  borderRadius: '0px 8px 8px 0px',
  left: 0,
  padding: '24px 32px',
  alignSelf: 'flex-start',
});

const ActionsButton = styled(IconButton)({
  border: '1px solid #BC9A67',
  position: 'relative',

  '&:disabled': {
    borderColor: 'inherit',
  },
});

const RecapWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  flex: 1,
});

const ActionSelection = styled(Box)({
  position: 'absolute',
  border: '1px solid #98A2B3',
  borderRadius: '16px',
  bottom: 'calc(100% + 16px)',
  left: '0',
  width: 'fit-content',
  background: 'white',
  boxShadow: '0px -1px 16px rgba(123, 123, 123, 0.1)',
});

const ActionSelectionText = styled(Typography)({
  color: '#475467',
  padding: '16px',
  borderBottom: '1px solid #98A2B3',
});

const RecapHeader = styled(Box)({
  padding: '10px 20px',
  background: '#F6F0E7',
  display: 'flex',
  borderRadius: '8px',
  justifyContent: 'space-between',
  width: '80%',
  fontWeight: 700,
  fontSize: '20px',
});

const RecapItem = styled(Box)({
  padding: '10px 20px',
  display: 'flex',
  borderRadius: '8px',
  alignSelf: 'flex-start',
  marginLeft: '10%',
  width: '80%',
  fontWeight: 600,
  fontSize: '16px',
  color: '#475467',
  gap: '20px',
});

const StyledControlLabel = styled(FormControlLabel)({
  color: 'black',
  borderBottom: '1px solid #98A2B3',
  padding: '16px',
  margin: 0,
  width: '100%',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
});

export default Recap;
