/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useEnv } from 'src/context/EnvProvider';
import useLoadDataList from 'src/hook/useLoadDataList';
import { useTexture } from 'src/hook/useTexture';
import { StateNames, States } from 'src/Screens/Configurator';
import { getGranits, Granit } from 'src/services/granits';
import { Piece } from 'src/services/pieces';
import { getPieceSize } from 'src/utils/configurator.utils';
import * as THREE from 'three';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RemoveIcon from '@mui/icons-material/Remove';

type TexturesProps = {
  pieceIndex: number;
  activePieces: any[];
  setActivePieces: (pieces: Piece[]) => void;
  customSteles: any[];
  setCustomSteles: (customSteles: any[]) => void;
  customPlatings: any[];
  setCustomPlatings: (customPlatings: any[]) => void;
  setPieceIndex: (pieceIndex: number) => void;
  getValue: <T extends StateNames>(stateName: T) => States[T];
  pieces: any[];
};

type SortType = 'ascending_price' | 'descending_price' | 'ascending_name' | 'descending_name';
const ORIGINS = ['FRENCH', 'CHINA', 'INDIA', 'OTHER', 'IMPORT'];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const Textures = ({
  activePieces,
  setActivePieces,
  pieceIndex,
  customSteles,
  setCustomSteles,
  customPlatings,
  setCustomPlatings,
  setPieceIndex,
  getValue,
  pieces,
}: TexturesProps) => {
  const { buckets } = useEnv();

  const { granits } = useTexture();
  const [selectedDominant, setSelectedDominant] = useState<string>();
  const [filter, setFilter] = useState<string>('');
  const [error, setError] = useState<string>();
  const [sort, setSort] = useState<SortType>('ascending_name');
  const [origin, setOrigin] = React.useState<string[]>([]);

  const checkMonumentSizeWithGranit = (pieceGeometry: Float32Array, granitMaxLength: string) => {
    const size = {
      x: getPieceSize('x', pieceGeometry),
      y: getPieceSize('y', pieceGeometry),
      z: getPieceSize('z', pieceGeometry),
    };

    if (
      Number.parseFloat(size.x) > Number.parseFloat(granitMaxLength) ||
      Number.parseFloat(size.y) > Number.parseFloat(granitMaxLength) ||
      Number.parseFloat(size.z) > Number.parseFloat(granitMaxLength)
    ) {
      setError(
        `Attention, une de vos pièces dépasse la taille maximale de ce granit (${Number.parseFloat(
          granitMaxLength,
        )}). Elle sera coupée.`,
      );
    }
  };

  const changeTexture = (granit: Granit, indexs: number[]) => {
    const loader = new THREE.TextureLoader();
    const loadedTexture = loader.load(
      `${buckets?.BUCKET_3D_PIECES || ''}/${granit.image}?t=${Date.now()}`,
    );

    loadedTexture.wrapS = THREE.RepeatWrapping;
    loadedTexture.wrapT = THREE.MirroredRepeatWrapping;
    // loadedTexture.offset.x = 0.5;
    loadedTexture.repeat.x = 1;

    let newActivePieces = [...activePieces];
    let newCustomStele = [...customSteles];
    let newCustomPlating = [...customPlatings];

    for (const index of indexs) {
      if (index < activePieces.length) {
        newActivePieces = newActivePieces.map((el, elIndex) => {
          if (
            elIndex === index ||
            (activePieces[index].underAssembly &&
              activePieces[index].underAssembly.uuid ===
                activePieces[elIndex].underAssembly?.uuid &&
              !getValue('hasAdvancedMode'))
          ) {
            checkMonumentSizeWithGranit(
              pieces[index].geometry.attributes.position.array,
              String(granit.maxLength),
            );
            return {
              ...el,
              granit,
              texture: loadedTexture,
            };
          }
          return el;
        });
      } else if (
        index >= activePieces.length &&
        index < activePieces.length + customSteles.length
      ) {
        loadedTexture.wrapS = THREE.RepeatWrapping;
        loadedTexture.wrapT = THREE.MirroredRepeatWrapping;
        loadedTexture.offset.x = 0.5;
        loadedTexture.repeat.x = 1;
        newCustomStele = newCustomStele.map((el, elIndex) => {
          if (index === activePieces.length + elIndex) {
            const extrudeGeometry = new THREE.ExtrudeGeometry(el.shape, el.extrudeSettings);
            checkMonumentSizeWithGranit(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              extrudeGeometry.attributes.position.array,
              String(granit.maxLength),
            );
            return {
              ...el,
              granit,
              texture: loadedTexture,
            };
          }
          return el;
        });
      } else if (index >= activePieces.length + customSteles.length) {
        loadedTexture.wrapS = THREE.RepeatWrapping;
        loadedTexture.wrapT = THREE.MirroredRepeatWrapping;
        loadedTexture.offset.x = 0.5;
        loadedTexture.repeat.x = 1;
        newCustomPlating = newCustomPlating.map((el) => {
          if (
            index >= activePieces.length + customSteles.length &&
            index < activePieces.length + customSteles.length + customPlatings.length
          ) {
            const extrudeGeometry = new THREE.ExtrudeGeometry(el.shape, {
              curveSegments: 12,
              steps: 2,
              depth: el.shapeDetails.depth,
              bevelEnabled: false,
            });
            checkMonumentSizeWithGranit(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              extrudeGeometry.attributes.position.array,
              String(granit.maxLength),
            );

            return {
              ...el,
              granit,
              texture: loadedTexture,
            };
          }
          return el;
        });
      }
    }

    setActivePieces(newActivePieces);
    setCustomPlatings(newCustomPlating);
    setCustomSteles(newCustomStele);
  };

  const selectedTexture = () => {
    if (pieceIndex < activePieces.length) {
      return activePieces[pieceIndex]?.granit;
    }
    if (
      pieceIndex >= activePieces.length &&
      pieceIndex < activePieces.length + customSteles.length
    ) {
      return customSteles[pieceIndex - activePieces.length]?.granit;
    }
    if (pieceIndex >= activePieces.length + customSteles.length) {
      return customPlatings[pieceIndex - activePieces.length + customSteles.length]?.granit;
    }
  };

  const activeGranits = useMemo(() => {
    const allPieces = [...activePieces, ...customSteles, ...customPlatings];
    const granitIds = allPieces.map((el) => el.granit?.id).filter((el) => el !== undefined);
    const uniqueIds = [...new Set(granitIds)];

    const granitsObject = uniqueIds?.map((el: string) => {
      const granit = allPieces?.find((element) => element.granit?.id === el)?.granit;

      return {
        id: el,
        granit,
      };
    });

    return granitsObject;
  }, [activePieces, customSteles, customPlatings]);

  const selectPieceWithGranitId = (granitId: string) => {
    for (const [elIndex, el] of activePieces.entries()) {
      if (el.granit?.id === granitId) {
        setPieceIndex(elIndex);
        return;
      }
    }
    for (const [elIndex, el] of customSteles.entries()) {
      if (el.granit?.id === granitId) {
        setPieceIndex(elIndex + activePieces.length);
        return;
      }
    }
    for (const [elIndex, el] of customPlatings.entries()) {
      if (el.granit?.id === granitId) {
        setPieceIndex(elIndex + activePieces.length + customPlatings.length);
        return;
      }
    }
  };

  const filteredGranits = useMemo(() => {
    let granitsToFilter = granits;
    if (pieceIndex < activePieces.length) {
      granitsToFilter = granitsToFilter.filter((el) => {
        if (activePieces[pieceIndex]?.underAssembly) {
          return activePieces[pieceIndex].underAssembly?.fabrications?.includes(el.fabrication);
        }
        return activePieces[pieceIndex]?.fabrications?.includes(el.fabrication);
      });
    }
    return granitsToFilter.filter((el) => {
      if (origin.length > 0 && !origin.includes(el.fabrication)) {
        if (!origin.includes('IMPORT') && el.fabrication !== 'FRENCH') return false;
        if (!origin.includes('FRENCH') && el.fabrication === 'FRENCH') return false;
      }
      return el.name
        ?.toLowerCase()
        .replace(' ', '')
        .includes(filter.toLocaleLowerCase().replace(' ', ''));
    });
  }, [pieceIndex, activePieces, granits, filter, origin]);

  const sortedGranits = useMemo(() => {
    return filteredGranits.sort((a, b) => {
      if (sort === 'ascending_name') {
        return a.name.localeCompare(b.name);
      }
      if (sort === 'descending_name') {
        return b.name.localeCompare(a.name);
      }
      if (sort === 'ascending_price') {
        return a.volumePrice - b.volumePrice;
      }
      if (sort === 'descending_price') {
        return b.volumePrice - a.volumePrice;
      }
      return 0;
    });
  }, [sort, filteredGranits]);

  const handleChangeGranit = (granit: Granit) => {
    setError(undefined);
    if (selectedDominant) {
      const piecesIdsToChange: number[] = [];
      for (const [elementIndex, element] of activePieces.entries()) {
        if (element.granit?.id === selectedDominant) {
          if (element.underAssembly) {
            if (element.underAssembly.fabrications.includes(granit.fabrication))
              piecesIdsToChange.push(elementIndex);
          } else if (element.fabrications.includes(granit.fabrication)) {
            piecesIdsToChange.push(elementIndex);
          }
        }
      }
      for (const [elementIndex, element] of customSteles.entries()) {
        if (element.granit?.id === selectedDominant) {
          piecesIdsToChange.push(elementIndex + activePieces.length);
        }
      }
      for (const [elementIndex, element] of customPlatings.entries()) {
        if (element.granit?.id === selectedDominant) {
          piecesIdsToChange.push(elementIndex + activePieces.length + customSteles.length);
        }
      }
      changeTexture(granit, piecesIdsToChange);
      setSelectedDominant(granit.id);
    } else changeTexture(granit, [pieceIndex]);
  };
  return (
    <Container>
      <ChipContainer>
        <Typography
          variant="body2"
          color="#667085"
          style={{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '12px',
          }}
        >
          Sélectionner
        </Typography>
        {activeGranits.map((el, elIndex) => {
          return (
            <Chip
              avatar={
                <Image
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  src={`${buckets?.BUCKET_3D_PIECES || ''}/${el.granit?.reducedImage}`}
                  alt="texture"
                  borderRadius={50}
                />
              }
              color={selectedDominant === el.id ? 'primary' : 'default'}
              label={`Dominante ${elIndex}`}
              onClick={() => {
                if (selectedDominant === el.id) {
                  setSelectedDominant(undefined);
                } else {
                  setSelectedDominant(el.id);
                  selectPieceWithGranitId(el.id);
                }
              }}
            />
          );
        })}
      </ChipContainer>

      {error ? (
        <Typography
          variant="subtitle2"
          sx={{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            marginBottom: '12px',
            paddingBottom: '16px',
            paddingTop: '16px',
          }}
          color="error"
        >
          {error}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          color="#667085"
          sx={{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '12px',
            paddingBottom: '16px',
            paddingTop: '16px',
          }}
        >
          Ou sélectionner une pièce sur le monument pour changer le granit.
        </Typography>
      )}

      <TextField
        id="outlined-basic"
        label="Rechercher un mot clé..."
        variant="outlined"
        sx={{
          width: '75%',
          input: {
            color: '#98A2B3',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
          },
        }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        InputProps={{
          startAdornment: <SearchIcon style={{ color: '#98A2B3' }} />,
        }}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '16px',
          marginBottom: '4px',
          gap: '8px',
        }}
      >
        <Chip
          avatar={
            sort.includes('name') ? (
              <>{sort === 'descending_name' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}</>
            ) : (
              <RemoveIcon />
            )
          }
          label="Nom"
          onClick={() => {
            if (sort === 'ascending_name') {
              setSort('descending_name');
            } else {
              setSort('ascending_name');
            }
          }}
        />
        <Chip
          avatar={
            sort.includes('price') ? (
              <>{sort === 'descending_price' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}</>
            ) : (
              <RemoveIcon />
            )
          }
          label="Prix M3"
          onClick={() => {
            if (sort === 'ascending_price') {
              setSort('descending_price');
            } else {
              setSort('ascending_price');
            }
          }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '4px',
          marginBottom: '16px',
          gap: '8px',
        }}
      >
        <Chip
          label="Français"
          color={origin.includes('FRENCH') ? 'primary' : 'default'}
          onClick={() => {
            if (origin.includes('FRENCH')) {
              setOrigin(origin.filter((el) => el !== 'FRENCH'));
            } else {
              setOrigin([...origin, 'FRENCH']);
            }
          }}
        />
        <Chip
          label="Import"
          color={origin.includes('IMPORT') ? 'primary' : 'default'}
          onClick={() => {
            if (origin.includes('IMPORT')) {
              setOrigin(origin.filter((el) => el !== 'IMPORT'));
            } else {
              setOrigin([...origin, 'IMPORT']);
            }
          }}
        />
      </div>
      <GranitContainer>
        {sortedGranits.map((el, index) => {
          return (
            <ImageContainer
              key={index}
              imageUrl={`${buckets?.BUCKET_3D_PIECES || ''}/${el.reducedImage}`}
              isSelected={selectedTexture()?.name === el.name}
              onClick={() => handleChangeGranit(el)}
            >
              <ImageNameContainer>
                <Typography
                  variant="body2"
                  color="#344054"
                  style={{
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    padding: '8px',
                  }}
                >
                  {el.name} {el.eco ? '(Eco granit)' : ''}
                </Typography>
              </ImageNameContainer>
            </ImageContainer>
          );
        })}
      </GranitContainer>
    </Container>
  );
};

const Container = styled('div')({
  padding: '22px',
  display: 'flex',
  flexDirection: 'column',

  maxHeight: window.innerHeight - 294,
  overflowY: 'auto',
});

const GranitContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '22px',
});

const ChipContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '22px',
  alignItems: 'center',
});

const Image = styled('img')(
  ({ isSelected, borderRadius }: { isSelected?: boolean; borderRadius?: number }) => ({
    width: '140px',
    height: '140px',
    borderRadius: borderRadius === undefined ? 5 : borderRadius,
    cursor: 'pointer',
    border: `${isSelected ? '4' : '0'}px solid #BC9A67`,
  }),
);

const ImageContainer = styled('div')(
  ({ isSelected, imageUrl }: { imageUrl: string; isSelected?: boolean }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    width: '140px',
    height: '140px',
    borderRadius: 10,
    cursor: 'pointer',
    border: `${isSelected ? '3' : '0'}px solid #BC9A67`,
    backgroundImage: `url("${imageUrl}")`,
    backgroundSize: '140px',
  }),
);

const ImageNameContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F2F4F7',
  width: '100%',
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
});
