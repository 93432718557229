/* eslint-disable unicorn/no-for-loop */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Edges,
  Environment,
  MeshReflectorMaterial,
  TransformControls,
  useCubeTexture,
  useEnvironment,
  useTexture,
} from '@react-three/drei';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useUser } from 'src/context/UserProvider';
import { QuoteStep } from 'src/Screens/project/components/NavBar';
import { compare2Float32Array, isAccesory } from 'src/utils/configurator.utils';
import { isAdmin } from 'src/utils/user.utils';
import * as THREE from 'three';
import { Vector2, Vector3 } from 'three';
import { useLoader } from '@react-three/fiber';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { getBoxWithPieceAndPosition, isAbove, isBelow, movePieceOnTop } from 'src/utils/piece';
import { Angles, StateNames, States } from '../../Screens/Configurator';

type PieceProps = {
  orbitRef: React.MutableRefObject<any>;
  indexPiece3D: number;
  pieceIndex: number;
  setPieceIndex: (pieceIndex: number) => void;

  piece: any;
  reloadPiece: boolean;
  selectedPoints: { pieceIndex: number; point: Float32Array }[] | null;
  setSelectedPoints: (seletedPoints: { pieceIndex: number; point: Float32Array }[] | null) => void;
  changePosition: (newPosition: Vector3, pieceIndexToChange?: number) => void;

  // eslint-disable-next-line react/no-unused-prop-types
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  getValue: (stateName: StateNames) => States[StateNames];
  activePieces: any[];
  setReloadPiece: (reloadPiece: boolean) => void;
  pieces: any[];
  checkCollision: (
    pieceIndexToCheck: number,
    axe: 'x' | 'y' | 'z',
    forceBox1?: {
      checkOnlySelectedPiece?: boolean;
      box1Geometry?: any[];
      box1Position?: { x: number; y: number; z: number };
    },
  ) => void;
  step: QuoteStep;
  selectedPattern: number | undefined;
  patterns: any;
  orientation: {
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  };
};

export const Piece = ({
  orbitRef,
  indexPiece3D,
  pieceIndex,
  setPieceIndex,
  piece,
  reloadPiece,
  selectedPoints,
  setSelectedPoints,
  changePosition,
  getValue,
  activePieces,
  setReloadPiece,
  pieces,
  checkCollision,
  step,
  selectedPattern,
  patterns,
  orientation,
}: PieceProps) => {
  const transformRef = useRef<any>();
  const meshRef = useRef<any>();

  useEffect(() => {
    if (transformRef.current && (step === 'CUSTOMIZATION' || step === 'ACCESSORIES')) {
      if (meshRef.current) transformRef.current.attach(meshRef.current);

      const controls = transformRef.current;
      const callback = (event: any) => {
        orbitRef.current.enabled = !event.value;

        if (orbitRef.current.enabled) setReloadPiece(true);
      };
      controls.addEventListener('dragging-changed', callback);
      return () => controls.removeEventListener('dragging-changed', callback);
    }
  });

  const changePiecePosition = () => {
    if (pieceIndex === indexPiece3D) {
      if (activePieces[pieceIndex].underAssembly && !getValue('hasAdvancedMode')) {
        const indexOfFirstPieceOnAssembly = activePieces.findIndex(
          (el) => el.underAssembly?.uuid === activePieces[pieceIndex].underAssembly.uuid,
        );
        const underAssembly = activePieces.filter(
          (el) =>
            el.underAssembly &&
            el.underAssembly.uuid === activePieces[pieceIndex].underAssembly.uuid,
        );
        const deltaPosition = new Vector3(
          (meshRef?.current?.position.x ?? 0) - pieces[pieceIndex].position.x,
          (meshRef?.current?.position.y ?? 0) - pieces[pieceIndex].position.y,
          (meshRef?.current?.position.z ?? 0) - pieces[pieceIndex].position.z,
        );
        for (const [elIndex, el] of underAssembly.entries()) {
          const newPosition = new Vector3(
            +pieces[indexOfFirstPieceOnAssembly + elIndex].position.x + deltaPosition.x,
            +pieces[indexOfFirstPieceOnAssembly + elIndex].position.y + deltaPosition.y,
            +pieces[indexOfFirstPieceOnAssembly + elIndex].position.z + deltaPosition.z,
          );
          changePosition(newPosition, indexOfFirstPieceOnAssembly + elIndex);
          setReloadPiece(true);
        }
      } else if (isAccesory(activePieces[pieceIndex].type)) {
        movePieceOnTop(pieces, pieceIndex, meshRef?.current?.position as Vector3);
      } else {
        changePosition(meshRef?.current?.position as Vector3);
      }
    }
  };

  const getShaderMaterial = (
    camera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
    texture: any,
    uRoomOrigin: Vector3,
    uRoomSize: Vector2,
    uRoomOrientation: Vector3,
    uTextureNormal: Vector3,
  ) => {
    const vertexShader = `
    varying vec4 vWorldPosition;
    varying vec3 vWorldNormal;

    void main() {
      vWorldPosition = modelMatrix * vec4(position, 1.0);
      vWorldNormal = normalize(mat3(modelMatrix) * normal);
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

    }
  `;
    const fragmentShader = `
    varying vec4 vWorldPosition;
    varying vec3 vWorldNormal;
    uniform sampler2D uTexture;
    uniform sampler2D uDepthTexture;
    uniform vec4 uClippingPlane;
    uniform vec3 uRoomOrigin;
    uniform vec2 uRoomSize;
    uniform vec3 uTextureNormal;
    uniform vec3 uCameraPosition;

    void main() {
      float clipDist = dot(vWorldPosition, uClippingPlane);
      // if (clipDist < 0.0) discard;

      //Ce bout de code permet de ne pas afficher la texture sur l'avant et l'arrière de la pièce en même temps
      vec3 viewVector = normalize(uCameraPosition - vWorldPosition.xyz);
      float viewDotNormal = dot(viewVector, vWorldNormal);
      if (viewDotNormal <= 0.0) discard;
    
      // Calculez l'angle entre la normale de la face et le vecteur de projection de la texture
      float angle = acos(dot(vWorldNormal, uTextureNormal));
  
      // Si l'angle est supérieur à 90 degrés, ne pas afficher le shader
      // if (angle > radians(90.0)) discard;

      vec3 worldPosition = vWorldPosition.xyz - uRoomOrigin;
      vec2 worldUv;
    
      if (abs(vWorldNormal.x) > 0.5) {
        if (float(uTextureNormal.x) == float(0)) discard;
        worldUv = vec2(worldPosition.z, worldPosition.y) / uRoomSize;
      } else if (abs(vWorldNormal.y) > 0.5) {
        if (float(uTextureNormal.y) == float(0)) discard;
        worldUv = vec2(worldPosition.x, worldPosition.z) / uRoomSize;
      } else {
        if (float(uTextureNormal.z) == float(0)) discard;
        worldUv = vec2(worldPosition.x, worldPosition.y) / uRoomSize;
      }
    
      if (worldUv.x < 0.0 || worldUv.x > 1.0 || worldUv.y < 0.0 || worldUv.y > 1.0) {
        // discard;
      }

      gl_FragColor = texture2D(uTexture, worldUv);
    }
    
  `;

    return new THREE.ShaderMaterial({
      transparent: true,
      polygonOffset: true,
      polygonOffsetFactor: -4,
      uniforms: {
        uRoomOrigin: {
          value: uRoomOrigin,
        },
        uRoomSize: { value: uRoomSize },
        uTexture: { value: texture },
        uProjectorViewProjectionMatrix: {
          value: new THREE.Matrix4().multiplyMatrices(
            camera.projectionMatrix,
            camera.matrixWorldInverse,
          ),
        },
        uClippingPlane: { value: new THREE.Vector4() },
        uRoomOrientation: { value: uRoomOrientation },
        uTextureNormal: { value: uTextureNormal }, // Définir la normale du plan
        uCameraPosition: { value: camera.position },
        uDepthTexture: { value: new THREE.DepthTexture(texture.image.width, texture.image.height) },
      },
      vertexShader,
      fragmentShader,
      clipping: true,
    });
  };

  const getMaterialWithTexture = (
    face: 'x' | 'y' | 'z' | 'x_inverse' | 'y_inverse' | 'z_inverse',
    texture: any,
    perspectiveCamera: any,
  ) => {
    const orthoProjector = new THREE.PerspectiveCamera();
    orthoProjector.copy(perspectiveCamera);
    orthoProjector.updateProjectionMatrix();
    orthoProjector.updateMatrixWorld(true);

    piece.geometry.computeBoundingBox();
    const boundingSize = piece.geometry.boundingBox.getSize(new THREE.Vector3());
    const boundingMin = piece.geometry.boundingBox.min.clone();
    const boundingMax = piece.geometry.boundingBox.max.clone();

    let uRoomOrigin: Vector3 | null = null;
    let uRoomOrientation: Vector3 | null = null;
    let uRoomSize: Vector2 | null = null;
    let clippingPlane: THREE.Plane | null = null;
    let uTextureNormal: Vector3 | null = null;

    if (face === 'z') {
      uRoomOrigin = new Vector3(
        boundingMin.x + piece.position.x,
        boundingMin.y + piece.position.y,
        boundingMin.z + piece.position.z,
      );
      uRoomSize = new THREE.Vector2(boundingSize.x, boundingSize.y);
      clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
      uRoomOrientation = new Vector3(0, 0, 1);
      uTextureNormal = new Vector3(0, 0, 1);
    }
    if (face === 'z_inverse') {
      uRoomOrigin = new Vector3(
        boundingMax.x + piece.position.x,
        boundingMin.y + piece.position.y,
        boundingMin.z + piece.position.z,
      );
      uRoomSize = new THREE.Vector2(-boundingSize.x, boundingSize.y);
      clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
      uRoomOrientation = new Vector3(0, 0, -1);
      uTextureNormal = new Vector3(0, 0, 1);
    }
    if (face === 'y') {
      uRoomOrigin = new Vector3(
        boundingMin.x + piece.position.x,
        boundingMax.y + piece.position.y,
        boundingMax.z + piece.position.z,
      );
      uRoomSize = new THREE.Vector2(boundingSize.x, -boundingSize.z);
      clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
      uRoomOrientation = new Vector3(0, 1, 0);
      uTextureNormal = new Vector3(0, 1, 0);
    }
    if (face === 'y_inverse') {
      uRoomOrigin = new Vector3(
        boundingMin.x + piece.position.x,
        boundingMin.y + piece.position.y,
        boundingMin.z + piece.position.z,
      );
      uRoomSize = new THREE.Vector2(boundingSize.x, boundingSize.z);
      clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
      uRoomOrientation = new Vector3(0, 1, 0);
      uTextureNormal = new Vector3(0, 1, 0);
    }
    if (face === 'x') {
      uRoomOrigin = new Vector3(
        boundingMax.x + piece.position.x,
        boundingMin.y + piece.position.y,
        boundingMax.z + piece.position.z,
      );
      uRoomSize = new THREE.Vector2(-boundingSize.z, boundingSize.y);
      clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
      uRoomOrientation = new Vector3(-1, 0, 0);
      uTextureNormal = new Vector3(1, 0, 0);
    }
    if (face === 'x_inverse') {
      uRoomOrigin = new Vector3(
        boundingMin.x + piece.position.x,
        boundingMin.y + piece.position.y,
        boundingMin.z + piece.position.z,
      );
      uRoomSize = new THREE.Vector2(boundingSize.z, boundingSize.y);
      clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
      uRoomOrientation = new Vector3(-1, 0, 0);
      uTextureNormal = new Vector3(1, 0, 0);
    }

    if (perspectiveCamera && uRoomOrigin && uRoomSize && uRoomOrientation && uTextureNormal) {
      const shaderMaterial = getShaderMaterial(
        orthoProjector,
        texture,
        uRoomOrigin,
        uRoomSize,
        uRoomOrientation,
        uTextureNormal,
      );
      if (clippingPlane) {
        shaderMaterial?.uniforms.uClippingPlane.value.set(
          clippingPlane.normal.x,
          clippingPlane.normal.y,
          clippingPlane.normal.z,
          clippingPlane.constant,
        );
      }
      return shaderMaterial;
    }
  };

  const getFaceMaterial = (face: 'x' | 'y' | 'z' | 'x_inverse' | 'y_inverse' | 'z_inverse') => {
    let perspectiveCamera: THREE.PerspectiveCamera | null = null;
    let texture: any | null = null;

    if (patterns[pieces[indexPiece3D].uuid] && patterns[pieces[indexPiece3D].uuid][face]) {
      if (patterns[pieces[indexPiece3D].uuid][face].camera)
        perspectiveCamera = patterns[pieces[indexPiece3D].uuid][face].camera;
      if (
        perspectiveCamera &&
        patterns[pieces[indexPiece3D].uuid][face].texture &&
        patterns[pieces[indexPiece3D].uuid][face].elements.length > 0
      ) {
        texture = patterns[pieces[indexPiece3D].uuid][face].texture;

        const material = getMaterialWithTexture(face, texture, perspectiveCamera);
        if (material) return material;
      }
    }

    return new THREE.MeshPhysicalMaterial({ transparent: true, opacity: 0 });
  };

  const getDefaultFaceMaterial = (
    face: 'x' | 'y' | 'z' | 'x_inverse' | 'y_inverse' | 'z_inverse',
  ) => {
    let perspectiveCamera: THREE.PerspectiveCamera | null = null;
    let texture: any | null = null;

    const activePiece = activePieces[indexPiece3D];

    if (activePiece && activePiece.defaultPatterns && activePiece.defaultPatterns[face]) {
      if (activePiece.defaultPatterns[face].camera)
        perspectiveCamera = activePiece.defaultPatterns[face].camera;
      if (
        perspectiveCamera &&
        activePiece.defaultPatterns[face].texture &&
        activePiece.defaultPatterns[face].elements.length > 0
      ) {
        texture = activePiece.defaultPatterns[face].texture;

        const material = getMaterialWithTexture(face, texture, perspectiveCamera);
        if (material) return material;
      }
    }

    return new THREE.MeshPhysicalMaterial({ transparent: true, opacity: 0 });
  };

  const faces = ['x', 'y', 'z', 'x_inverse', 'y_inverse', 'z_inverse'] as const;

  return (
    <Fragment key={indexPiece3D}>
      <TransformControls
        ref={transformRef}
        mode="translate"
        size={
          !activePieces[pieceIndex]?.isBlocked &&
          pieceIndex === indexPiece3D &&
          (step === 'CUSTOMIZATION' || step === 'ACCESSORIES')
            ? 1
            : 0
        }
        onMouseUp={() => changePiecePosition()}
      >
        <>
          {orientation.key !== 'free' && step === 'PATTERNS'
            ? null
            : faces.map((el, elIndex) => {
                return (
                  <mesh
                    // eslint-disable-next-line react/no-array-index-key
                    key={elIndex}
                    geometry={piece.geometry}
                    material={getFaceMaterial(el)}
                    position={piece.position}
                  />
                );
              })}

          {faces.map((el, elIndex) => {
            return (
              <mesh
                // eslint-disable-next-line react/no-array-index-key
                key={elIndex}
                geometry={piece.geometry}
                material={getDefaultFaceMaterial(el)}
                position={piece.position}
              />
            );
          })}

          {getValue('wired') ? (
            <mesh
              ref={meshRef}
              onClick={(e) => {
                if (
                  e.intersections &&
                  e.intersections[0].distance === e.distance &&
                  pieceIndex !== indexPiece3D
                )
                  setPieceIndex(indexPiece3D);
              }}
              key={indexPiece3D}
              geometry={piece.geometry}
              position={piece.position}
            >
              <>
                {pieceIndex !== indexPiece3D ||
                (step !== 'CUSTOMIZATION' && step !== 'PATTERNS') ? (
                  <Edges color="black" threshold={10} />
                ) : null}

                <meshStandardMaterial transparent opacity={0} />
                {pieceIndex === indexPiece3D &&
                (step === 'CUSTOMIZATION' || step === 'PATTERNS' || step === 'ACCESSORIES') ? (
                  <Edges color="red" threshold={10} />
                ) : null}
              </>
            </mesh>
          ) : (
            <mesh
              ref={meshRef}
              onClick={(e) => {
                if (
                  e.intersections &&
                  e.intersections[0].distance === e.distance &&
                  pieceIndex !== indexPiece3D
                )
                  setPieceIndex(indexPiece3D);
              }}
              key={indexPiece3D}
              geometry={piece.geometry}
              position={piece.position}
            >
              <>
                {getValue('texture') ? (
                  <meshStandardMaterial
                    map={activePieces[indexPiece3D].texture}
                    metalness={0.95}
                    roughness={0.15}
                  />
                ) : (
                  <>
                    <meshStandardMaterial transparent={false} opacity={1} />
                  </>
                )}
                {pieceIndex === indexPiece3D &&
                (step === 'CUSTOMIZATION' || step === 'PATTERNS' || step === 'ACCESSORIES') ? (
                  <Edges color="red" threshold={10} />
                ) : null}
                {getValue('texture') ? (
                  <>
                    {/* {pieceIndex !== indexPiece3D ||
                    (step !== 'CUSTOMIZATION' && step !== 'PATTERNS') ? (
                      <Edges color="white" threshold={20} />
                    ) : null} */}
                  </>
                ) : (
                  <>
                    {pieceIndex !== indexPiece3D ||
                    (step !== 'CUSTOMIZATION' && step !== 'PATTERNS') ? (
                      <Edges color="black" threshold={10} />
                    ) : null}
                  </>
                )}
              </>
            </mesh>
          )}
        </>
      </TransformControls>
      {getValue('points') && !reloadPiece && indexPiece3D === pieceIndex ? (
        <Angles
          pieceIndex={pieceIndex}
          piece={piece}
          selectedPoints={selectedPoints}
          selectPoints={(pts) => {
            if (selectedPoints) {
              const findedPoint = selectedPoints.find(
                (el) => el.pieceIndex === pieceIndex && compare2Float32Array(el.point, pts),
              );

              if (findedPoint) {
                setSelectedPoints(
                  selectedPoints.filter((item: any) => {
                    return (
                      !compare2Float32Array(item.point as Float32Array, pts) ||
                      pieceIndex !== item.pieceIndex
                    );
                  }),
                );
              } else {
                setSelectedPoints([...selectedPoints, { point: pts, pieceIndex }]);
              }
            } else {
              setSelectedPoints([{ point: pts, pieceIndex }]);
            }
          }}
        />
      ) : null}
    </Fragment>
  );
};
