/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useEffect, useRef, useState } from 'react';
import { Image, Transformer } from 'react-konva';
import { Euler, Vector2, Vector3 } from 'three';
import * as THREE from 'three';

type KanvasProps = {
  pattern: {
    imageUrl: string;
    inverse_horizontal: boolean;
    inverse_vertical: boolean;
    position: Vector3;
    rotation: Euler;
    scale: Vector2;
    default: Vector2;
    pieceUUID: string;
    orientation: string;
    uuid: string;
  };
  stageRef: React.MutableRefObject<any>;
  orientation: {
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  };
  setPatterns: (patterns: { [key: string]: { [key: string]: any }[] }) => void;
  patterns: {
    [key: string]: { [key: string]: any }[];
  };
  pieceUUID: string;
  isSelected: boolean;
  setSelectedPattern: (selectedPattern: number | undefined) => void;
  patternIndex: number;
  orbitRef: React.MutableRefObject<any>;
  kanvasSize: { width: number; height: number } | undefined;
  ratio: number | undefined;
  isMouseOverCanvas: boolean;
  isPatternsLoaded: { [key: string]: boolean };
  setIsPatternsLoaded: (isPatternsLoaded: { [key: string]: boolean }) => void;
};

export const KanvasPattern = ({
  pattern,
  orientation,
  setPatterns,
  patterns,
  pieceUUID,
  stageRef,
  isSelected,
  setSelectedPattern,
  patternIndex,
  orbitRef,
  kanvasSize,
  ratio,
  isMouseOverCanvas,
  isPatternsLoaded,
  setIsPatternsLoaded,
}: KanvasProps) => {
  const [image, setImage] = useState<HTMLImageElement>();
  const [imageURL, setImageUrl] = useState<string>();
  const [isOnRendering, setIsOnRendering] = useState<boolean>(false);
  const trRef = useRef<any>();
  const shapeRef = useRef<any>();

  useEffect(() => {
    if (imageURL !== pattern.imageUrl) {
      setImage(undefined);
      setIsOnRendering(true);
      loadImage();
    }
    if (!trRef.current || isOnRendering === true || !shapeRef.current) {
      return;
    }
    trRef.current.nodes([shapeRef.current]);
    trRef.current.getLayer().batchDraw();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnRendering, isSelected, pattern, isMouseOverCanvas, imageURL]);

  const loadImage = useCallback(() => {
    setIsPatternsLoaded({ ...isPatternsLoaded, [patternIndex]: true });
    const imageToLoad = new window.Image();
    imageToLoad.src = `${pattern.imageUrl}?t=${Date.now()}`;
    imageToLoad.crossOrigin = 'anonymous';
    imageToLoad.addEventListener('load', () => {
      setImage(imageToLoad);
      setImageUrl(pattern.imageUrl);
      setIsPatternsLoaded({ ...isPatternsLoaded, [patternIndex]: false });
    });
  }, [pattern.imageUrl, isPatternsLoaded, setIsPatternsLoaded, patternIndex]);

  const changePattern = (obj: { position?: Vector3; rotation?: Euler; scale?: Vector2 }) => {
    setIsOnRendering(true);

    const patternsTmp = { ...patterns };

    patternsTmp[pattern.pieceUUID][pattern.orientation].elements = patterns[pattern.pieceUUID][
      pattern.orientation
    ].elements.map((element: any) => {
      if (element.uuid === pattern.uuid) {
        return {
          ...element,
          position: obj.position ?? element.position,
          scale: obj.scale ?? element.scale,
          rotation: obj.rotation ?? element.rotation,
        };
      }
      return element;
    });

    if (
      stageRef.current &&
      patternsTmp[pieceUUID][`${orientation.key}${orientation.inverse ? '_inverse' : ''}`]
    ) {
      const canvas = stageRef.current.toCanvas();
      const texture = new THREE.CanvasTexture(canvas);
      patternsTmp[pieceUUID][`${orientation.key}${orientation.inverse ? '_inverse' : ''}`].texture =
        texture;

      patternsTmp[pieceUUID][`${orientation.key}${orientation.inverse ? '_inverse' : ''}`].camera =
        orbitRef.current.object.clone();
    }

    setPatterns(patternsTmp);
    setIsOnRendering(false);
  };

  const handleDragEnd = (e: any) => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    node.scaleX(1);
    node.scaleY(1);

    const newScaleX = Number.parseFloat(
      (Math.round(((node.width() * scaleX) / (ratio ?? 1)) * 2) / 2).toFixed(2),
    );
    const newScaleY = Number.parseFloat(
      (Math.round(((node.height() * scaleY) / (ratio ?? 1)) * 2) / 2).toFixed(2),
    );

    changePattern({
      scale: new Vector2(newScaleX, newScaleY),
      rotation: new Euler(node.rotation(), 0, 0, 'XYZ'),
      position: new Vector3(
        Number.parseFloat((Math.round(e.target.x()) / (ratio ?? 1)).toFixed(2)),
        Number.parseFloat((Math.round(e.target.y()) / (ratio ?? 1)).toFixed(2)),
        0,
      ),
    });
  };

  if (!image || imageURL !== pattern.imageUrl) return <></>;

  return (
    <>
      <Image
        ref={shapeRef}
        onClick={() => {
          setSelectedPattern(patternIndex);
        }}
        onTap={() => {
          setSelectedPattern(patternIndex);
        }}
        width={Number.parseFloat((pattern.scale.x * (ratio ?? 1)).toFixed(2))}
        height={Number.parseFloat((pattern.scale.y * (ratio ?? 1)).toFixed(2))}
        rotation={pattern.rotation.x}
        image={image}
        x={Number.parseFloat((pattern.position.x * (ratio ?? 1)).toFixed(2))}
        y={Number.parseFloat((pattern.position.y * (ratio ?? 1)).toFixed(2))}
        draggable
        onTransformEnd={handleDragEnd}
        onDragEnd={handleDragEnd}
        onMouseEnter={(e) => {
          // lors de la survenance de l'évènement, change le curseur en pointer
          const container = e.target?.getStage()?.container();
          if (container) container.style.cursor = 'pointer';
        }}
        onMouseLeave={(e) => {
          // lors de la fin de l'évènement, réinitialise le curseur
          const container = e.target?.getStage()?.container();
          if (container) container.style.cursor = 'default';
        }}
      />

      {isSelected && !isOnRendering && isMouseOverCanvas ? (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      ) : null}
    </>
  );
};
